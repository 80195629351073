import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import ListInTable from "../../Reusable Components/DataTable";
import "../../App.sass";
import "../AdminPages/style.css";
import { PRODUCT_API } from "../../API/addProductAPI";
import baseUrl from "../../API/apiUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DESIGN_API } from "../../API/designAPI";
import { Button } from "@mui/material";
import { ProfileContext } from "../../App";

const MyOrders = () => {
  const { user } = useContext(ProfileContext);
  var arrayData = [];
  const userColumns = [
    {
      field: "id",
      headerName: "Id",
      headerClassName: "custom_header",
      width: 100,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.id}</div>;
      },
    },
    {
      field: "productName",
      headerName: "Product Name",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.title}</div>;
      },
    },
    {
      field: "vendorName",
      headerName: "Vendor Name",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.buildingType}</div>;
      },
    },
    {
      field: "orderId",
      headerName: "Order ID",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.area}</div>;
      },
    },
    {
      field: "orderType",
      headerName: "Order Type",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.floor}</div>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      headerClassName: "custom_header",
      width: 100,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.bedroom}</div>;
      },
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.toilet}</div>;
      },
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.description}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "custom_header",
      width: 120,
      renderCell: (params) => {
        return <div>{params.row.status}</div>;
      },
    },
    {
      field: "address",
      headerName: "Address",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div>{params.row.status}</div>;
      },
    },
    {
      field: "otp",
      headerName: "OTP",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div>{params.row.status}</div>;
      },
    },
  ];

  const [rows, setRows] = useState([]);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      headerClassName: "custom_header",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cell_action_div">
            <Link
              to="#"
              style={{ textDecoration: "none", color: "unset" }}
              className="view_btn"
            >
              Product
            </Link>
            <Link
              to="#"
              style={{ textDecoration: "none", color: "unset" }}
              className="view_btn"
            >
              Payment
            </Link>
            <Link
              to="#"
              style={{ textDecoration: "none", color: "unset" }}
              className="view_btn"
            >
              User
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    document.title = "MyGriha | Dealer Orders";
  }, []);

  rows?.map((item) => {
    return arrayData?.push({
      id: item._id,
      title: item.title,
      buildingType: item.buildingType,
      area: item.plotLength * item.width,
      floor: item.floor,
      bedroom: item.bedroom,
      toilet: item.toilet,
      description: item.description,
      status: item.status,
    });
  });

  return (
    <>
      <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <UserTable className="users_list_container">
            <div className="users_list_container_title">
              <h4 className="p-2 mb-0">My Orders</h4>
            </div>
            <div>
              <ListInTable
                rows={arrayData}
                columns={userColumns.concat(actionColumn)}
              />
            </div>
          </UserTable>
        </div>
      </main>
    </>
  );
};

export const UserTable = styled.div`
  z-index: 0;
  /* Resetting MUI table color props */
  p,
  div.MuiTablePagination-actions > button {
    color: inherit;
  }
  /* END */
`;

export default MyOrders;
