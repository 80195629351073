import React, { useContext, useState } from "react";
import "../Css/login.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API } from "../API/usersAPI";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProfileContext } from "../App";

const Login = ({ setActiveTab }) => {
  const { setMessage, setUser } = useContext(ProfileContext);
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };
  const handleLogin = (e) => {
    e.preventDefault();
    if (!loginData.email) {
      toast.error("Email is required...", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!loginData.password) {
      toast.error("Password is required...", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      API.login(loginData).then(async (response) => {
        if (response.status === 200) {
          // console.log(response.data)
          await setMessage(response.message);
          await setUser(response.data);
          localStorage.setItem("user", JSON.stringify(response.data));
          navigate(
            response?.role?.substring(5).toLowerCase() === "admin"
              ? `/${response.role.substring(5).toLowerCase()}/dashboard`
              : `/${response.role.substring(5).toLowerCase()}/profile`
          );
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="container-fluid login-sections">
        <div className="login-container">
          <form className="login-form" onSubmit={handleLogin}>
            <h1 className="text-center">Welcome Back</h1>
            <p className="text-center">Please login to your account</p>
            <div className="input-group">
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                value={loginData.email}
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                value={loginData.password}
              />
            </div>
            <button type="submit" className="button-css">
              Login
            </button>
            <div className="bottom-text">
              <p>
                Don't have an account? <Link to="/signup">Sign Up</Link>
              </p>
              <p>
                <Link to="/forgot-p">Forgot Password?</Link>
              </p>
            </div>
          </form>
        </div>
      </div>

      {/* <section className="login-bg">
        <div className="container">
          <div className="sign-f">
            <form className="form-sg" onSubmit={handleLogin}>
              <h3 className="sign-h">Login</h3>
              <div className="row">
                <div className="col-lg-12">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="sign-input"
                    onChange={handleChange}
                    value={loginData.email}
                  />
                </div>
                <div className="col-lg-12">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    className="sign-input"
                    onChange={handleChange}
                    value={loginData.password}
                  />
                </div>
              </div>
              <button type="submit" className="sign-b">
                Login
              </button>
              <br />
              <Link to="/ForgotPass" className="forg-p">
                Forgot Password?
              </Link>
              <Link to="/signup">
                <Button size="small" variant="contained" color="warning">
                  SignUp
                </Button>
              </Link>
            </form>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Login;
