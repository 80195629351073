import React from 'react'

const MyNotifications = () => {
  return (
    <div>
      architect notification
    </div>
  )
}

export default MyNotifications
