import React, { useEffect, useState } from "react";
import "./architect.css";
import Navbar from "../../common/Navbar";
import Footer from "../../component/HomeComponents/Footer";
import Sidebar from "../../component/HomeComponents/Sidebar";
import { DESIGN_API } from "../../API/designAPI";
import baseUrl from "../../API/apiUrl";

const ShowPackages = () => {
  const [designList, setDesignList] = useState([]);
  useEffect(() => {
    DESIGN_API.getAllDesign().then((resp) => {
      if (resp.status === 200) {
        setDesignList(resp.data.filter((item) => item.status === "Approved"));
      } else {
        setDesignList([]);
      }
    });
  }, []);
  return (
    <>
      <Navbar />
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-10">
              <div className="row">
                <h2 className="arch-exp">Build home with your own choice</h2>
                {designList?.length > 0 &&
                  designList?.map((item, index) => {
                    return (
                      <div className="col-lg-3">
                        <div className="card">
                          <img
                            className="card-img-top"
                            src={`${baseUrl}${item.twoDImage}`}
                          />

                          <div className="card-img-overlay card-inverse">
                            <h5 className="text-stroke text-danger text-center fw-bold pb-5">
                              {item.title}
                            </h5>
                          </div>
                          <div className="d-flex mt-2 plot-area p-2">
                            <p>
                              Plot Length*width - {item.plotLength}x{item.width}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ShowPackages;
