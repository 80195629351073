import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import ListInTable from "../../Reusable Components/DataTable";
import styled from "styled-components";
import "../../App.sass";
import "./style.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { API } from "../../API/usersAPI";

const UserList = () => {
    var arrayData = [];
    const [rows, setRows] = useState([]);
  const userColumns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "custom_header",
      width: 250,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.id}</div>;
      },
    },
    {
      field: "username",
      headerName: "User Name",
      headerClassName: "custom_header",
      width: 200,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.username}</div>;
      },
    },
    {
      field: "role",
      headerName: "Role",
      headerClassName: "custom_header",
      width: 200,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.role}</div>;
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "custom_header",
      width: 300,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.email}</div>;
      },
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      headerClassName: "custom_header",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cell_action_div">
            <Link
              to={`/profile/view/${params.row.id}`}
              style={{ textDecoration: "none", color: "unset" }}
              className="view_btn"
            >
              View
            </Link>
            <div
              className="delete_btn"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  function handleDelete(id) {
    if (window.confirm("Are you sure want to delete?")) {
        
      }
  }

  useEffect(() => {
    document.title = "Users | Admin Dashboard";
    getAllUserList();
  },[]);

  const getAllUserList = () => {
    API.getAllUsers().then((resp) => {
      if (resp.status === 200) {
        setRows(resp.data);
      } else {
        setRows([]);
      }
    });
  };

  rows?.map((item) => {
    return arrayData?.push({
      id: item._id,
      username: item.username,
      email: item.email,
      role:item?.role?.substring(5).toLowerCase()
    });
  });
  return (
    <>
    <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <UserTable className="users_list_container">
            <div className="users_list_container_title">
              <h4 className="p-2 mb-0">User List</h4>
            </div>
            <div >
            <ListInTable
              rows={arrayData}
              columns={userColumns.concat(actionColumn)}
            />
            </div>
          </UserTable>
        </div>
      </main>
    </>
  )
}
export const UserTable = styled.div`
  z-index: 0;
  /* Resetting MUI table color props */
  p,
  div.MuiTablePagination-actions > button {
    color: inherit;
  }
  /* END */
`;
export default UserList
