import URL from "./apiUrl";

export const PRODUCT_API = {
    addProduct: async(data)=>{
        // console.log(data.get("categoryName"))
        try{
            const response = await fetch(`${URL}product/add`, {
                method: "post",
                body: (data),
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    getAllProduct: async()=>{
        try{
            const response = await fetch(`${URL}product/all`, {
                method: "get",
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    deleteProductById: async(id)=>{
        try{
            const response = await fetch(`${URL}product/delete/${id}`, {
                method: "delete",
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    getProductById: async(id)=>{
        try{
            const response = await fetch(`${URL}product/details/${id}`, {
                method: "get",
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    getProductByUser: async (id) => {
        try {
          const response = await fetch(URL + `product/productByUser/${id}`, {
            method: "get",
          });
          const returnData = await response.json();
          return returnData;
        } catch (error) {
          return error;
        }
      },
    updateProduct: async(data,id)=>{
        try{
            const response = await fetch(`${URL}product/update/${id}`, {
                method: "put",
                body: (data),
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    updateProductStatus: async (data, id) => {
        // console.log(data,id)
        try {
          const response = await fetch(`${URL}product/update-status/${id}`, {
            method: "put",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          });
          const returnData = await response.json();
          return returnData;
        } catch (error) {
          return error;
        }
      },
    
}