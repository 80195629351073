import URL from "./apiUrl";

export const CATEGORY_API = {
    addCategory: async(data)=>{
        // console.log(data.get("categoryName"))
        try{
            const response = await fetch(`${URL}category/add`, {
                method: "post",
                body: (data),
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    getAllCategory: async()=>{
        try{
            const response = await fetch(`${URL}category/all`, {
                method: "get",
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    deleteCategoryById: async(id)=>{
        try{
            const response = await fetch(`${URL}category/delete/${id}`, {
                method: "delete",
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    getCategoryById: async(id)=>{
        try{
            const response = await fetch(`${URL}category/details/${id}`, {
                method: "get",
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    updateCategory: async(data,id)=>{
        // console.log(data.get("categoryName"))
        try{
            const response = await fetch(`${URL}category/update/${id}`, {
                method: "put",
                body: (data),
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    // getMaterial: async(data)=>{
    //     try{
    //         const response = await fetch(`${URL}materials/get-material/${data}`, {
    //             method: "get",
    //         })
    //         const returnData = await response.json();
    //         return returnData;
    //     }
    //     catch(error){
    //         return error;
    //     }
    // },
    // getMaterialById: async(id)=>{
    //     try{
    //         const response = await fetch(`${URL}materials/material/${id}`, {
    //             method: "get",
    //         })
    //         const returnData = await response.json();
    //         return returnData;
    //     }
    //     catch(error){
    //         return error;
    //     }
    // },
    // selectUpdate: async(data)=>{
    //     try{
    //         const response = await fetch(`${URL}materials/update-material`, {
    //             method: "put",
    //             body: JSON.stringify(data),
    //             headers: {
    //                 "Content-Type": "application/json"
    //             }
    //         })
    //         const returnData = await response.json();
    //         return returnData;
    //     }
    //     catch(error){
    //         return error;
    //     }
    // },
    // getMaterialRate: async(data)=>{
    //     try{
    //         const response = await fetch(`${URL}material-rate/get`, {
    //             method: "post",
    //             body: JSON.stringify(data),
    //             headers: {
    //                 "Content-Type": "application/json"
    //             }
    //         })
    //         const returnData = await response.json();
    //         return returnData;
    //     }
    //     catch(error){
    //         return error;
    //     }
    // }
}