import React, { useEffect, useContext, useState } from "react";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import "../../App.sass";
import "../Home/Home.sass";
import "../AdminPages/style.css";
import { ProfileContext } from "../../App";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { State, City } from "country-state-city";
import { PRODUCT_API } from "../../API/addProductAPI";
import { CATEGORY_API } from "../../API/addCategoryAPI";

const AddProduct = () => {
  const { user } = useContext(ProfileContext);
  const navigate = useNavigate();
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [unitName, setUnitName] = useState("");
  const [productData, setProductData] = useState({
    serviceLocationState: "",
    serviceLocationCity: "",
    category: "",
    name: "",
    photo: "",
    quantity: "",
    unit: "",
    price: "",
    descriptionOne: "",
    descriptionTwo: "",
    descriptionThree: "",
  });
  useEffect(() => {
    document.title = "MyGriha | Upload Product";
    setStateList(State.getStatesOfCountry("IN"));
    CATEGORY_API.getAllCategory().then((resp) => {
      if (resp.status === 200) {
        setCategoryList(resp.data);
      } else {
        setCategoryList([]);
      }
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      setProductData({ ...productData, [name]: e.target.files[0] });
    } else {
      if (name === "serviceLocationState") {
        setProductData({
          ...productData,
          [name]: value,
        });
        setCityList(City.getCitiesOfState("IN", value));
      } else if (name === "unit") {
        setUnitName(value);
        setProductData({
          ...productData,
          [name]: value,
        });
      } else {
        setProductData({ ...productData, [name]: value });
      }
    }
  };

  const productSubmit = (e) => {
    e.preventDefault();
    if (!productData.serviceLocationState) {
      toast.error("Service location state required..", {
        position: "top-right",
      });
    } else if (!productData.serviceLocationCity) {
      toast.error("Service location city required..", {
        position: "top-right",
      });
    } else if (!productData.category) {
      toast.error("Product category required..", {
        position: "top-right",
      });
    } else if (!productData.name) {
      toast.error("Product name required..", {
        position: "top-right",
      });
    } else if (!productData.photo) {
      toast.error("Product image required..", {
        position: "top-right",
      });
    } else if (!productData.quantity) {
      toast.error("Minimum product quantity required..");
    } else if (!productData.unit) {
      toast.error("Product measure unit required..");
    } else if (!productData.price) {
      toast.error("Product price required..", {
        position: "top-right",
      });
    } else if (!productData.descriptionOne) {
      toast.error("Product description required..", {
        position: "top-right",
      });
    } else {
      const formData = new FormData();
      formData.append(
        "serviceLocationState",
        State.getStateByCodeAndCountry(productData.serviceLocationState, "IN")
          .name
      );
      formData.append("serviceLocationCity", productData.serviceLocationCity);
      formData.append("category", productData.category);
      formData.append("name", productData.name);
      formData.append("photo", productData.photo);
      formData.append("quantity", productData.quantity);
      formData.append("unit", productData.unit);
      formData.append("price", productData.price);
      formData.append("descriptionOne", productData.descriptionOne);
      formData.append("descriptionTwo", productData.descriptionTwo);
      formData.append("descriptionThree", productData.descriptionThree);
      formData.append("uploadingUser", user?._id);
      formData.append("status", "Approved");
      formData.append("uploadingUserRole", "ROLE_ADMIN");
      PRODUCT_API.addProduct(formData).then((resp) => {
        if (resp.status === 201) {
          toast.success(resp.message, {
            position: "top-right",
          });
          navigate("/admin/product");
        } else {
          toast.error(resp.message, {
            position: "top-right",
          });
        }
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel" id="report_page">
          <Navbar />
          <div className="cards_container">
            <div className="profile_title">
              <h5 className="p-2 mb-0">Upload Product</h5>
            </div>
          </div>
          <div className="cards_container">
            <div className="profile_container">
              <form onSubmit={productSubmit}>
                <div className="row m-5">
                  <div className="col-md-6 ">
                    <label htmlFor="profile">
                      Service Location State
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select "
                      name="serviceLocationState"
                      value={productData.serviceLocationState}
                      onChange={handleChange}
                    >
                      <option>--Select State--</option>
                      {stateList?.length > 0 &&
                        stateList?.map((state, index) => {
                          return (
                            <option key={index} value={state.isoCode}>
                              {state.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Service Location City
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select "
                      name="serviceLocationCity"
                      value={productData.serviceLocationCity}
                      onChange={handleChange}
                    >
                      <option>--Select City--</option>
                      {cityList?.length > 0 &&
                        cityList?.map((city, index) => {
                          return (
                            <option key={index} value={city.name}>
                              {city.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Product Category
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select "
                      name="category"
                      value={productData.category}
                      onChange={handleChange}
                    >
                      <option value="">Select Category</option>
                      {categoryList?.length > 0 &&
                        categoryList?.map((item, index) => {
                          return (
                            <option key={index} value={item.category}>
                              {item.category}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Photo<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      id="profile"
                      name="photo"
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="profile"
                      value={productData.name}
                      name="name"
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Quantity<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      id="profile"
                      name="quantity"
                      value={productData.quantity}
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Unit<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="profile"
                      name="unit"
                      value={productData.unit}
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Price {unitName ? ` (per ${unitName})`:""}<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      id="profile"
                      name="price"
                      value={productData.price}
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Description One<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="profile"
                      name="descriptionOne"
                      value={productData.descriptionOne}
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">Description Two</label>
                    <input
                      type="text"
                      id="profile"
                      name="descriptionTwo"
                      value={productData.descriptionTwo}
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">Description Three</label>
                    <input
                      type="text"
                      id="profile"
                      name="descriptionThree"
                      value={productData.descriptionThree}
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-success float-end m-5">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddProduct;
