import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { v4 as uuidv4 } from "uuid";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import ListInTable from "../../Reusable Components/DataTable";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { productListTableColumns } from "./CategoryData";
import "../../Reusable Styling/AddItem.sass";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import { ProfileContext } from "../../App";
import { PRODUCT_API } from "../../API/addProductAPI";
import { CATEGORY_API } from "../../API/addCategoryAPI";
import { DESIGN_API } from "../../API/designAPI";
import { State, City } from "country-state-city";

const AddDesign = () => {
  const navigate = useNavigate();
  const { setMessage, user } = useContext(ProfileContext);
  const [title, setTitle] = useState("");
  const [plotLength, setPlotLength] = useState("");
  const [width, setWidth] = useState("");
  const [buildingType, setBuildingType] = useState("");
  const [bedroom, setBedroom] = useState("");
  const [toilet, setToilet] = useState("");
  const [floor, setFloor] = useState("");
  const [twoDImage, setTwoDImage] = useState("");
  const [cadImage, setCadImage] = useState("");
  const [cadPrice, setCadPrice] = useState("");
  const [threeDImage, setThreeDImage] = useState("");
  const [description, setDescription] = useState("");
  const [serviceLocationState, setServiceLocationState] = useState("");
  const [serviceLocationCity, setServiceLocationCity] = useState("");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    document.title = "New Design | Admin Dashboard";
    setStateList(State.getStatesOfCountry("IN"));
  }, []);

  const productSubmit = (e) => {
    e.preventDefault();
    if (!serviceLocationState) {
      toast.error("Service location state required..");
    } else if (!serviceLocationCity) {
      toast.error("Service location city required..");
    } else if (!title) {
      toast.error("Building title is required..");
    } else if (!plotLength) {
      toast.error("Plot length required..");
    } else if (!width) {
      toast.error("Width required..");
    } else if (!buildingType) {
      toast.error("Building type required..");
    } else if (!twoDImage) {
      toast.error("TwoD image required..");
    } else if (!cadImage) {
      toast.error("CAD file required..");
    } else if (!cadPrice) {
      toast.error("CAD Price required..");
    }
    else {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("plotLength", plotLength);
      formData.append("width", width);
      formData.append("buildingType", buildingType);
      formData.append("bedroom", bedroom);
      formData.append("toilet", toilet);
      formData.append("floor", floor);
      formData.append("twoDImage", twoDImage);
      formData.append("threeDImage", threeDImage);
      formData.append("cadImage", cadImage);
      formData.append("cadPrice", cadPrice);
      formData.append("description", description);
      formData.append(
        "serviceLocationState",
        serviceLocationState
      );
      formData.append("serviceLocationCity", serviceLocationCity);
      formData.append("uploadingUser", user?._id);
      formData.append("uploadingUserRole", "ROLE_ADMIN");
      formData.append("status", "Approved");
      DESIGN_API.addDesign(formData).then((resp) => {
        // console.log(resp)
        if (resp.status === 201) {
          setMessage(resp.message);
          setTitle("");
          setPlotLength("");
          setWidth("");
          setBedroom("");
          setToilet("");
          setFloor("");
          setTwoDImage("");
          setThreeDImage("");
          setCadImage("");
          setCadPrice("");
          setBuildingType("");
          setDescription("");
          setServiceLocationState("");
          setServiceLocationCity("");
          navigate("/admin/design");
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <div className="add_item_title_div mt-4">
            <h6>Add Design</h6>
          </div>
          <div className="row ">
            <div className="container">
              <div className="row product-box px-5 pe-5">
                <div className="col-lg-12 p-4">
                  <form onSubmit={productSubmit}>
                    <div className="row">
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Service Location State
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="p-1 form-select rounded-0"
                            name="serviceLocationState"
                            value={serviceLocationState}
                            onChange={(e) => {
                              setServiceLocationState(e.target.value);
                              setCityList(
                                City.getCitiesOfState("IN", e.target.value)
                              );
                            }}
                          >
                            <option value="">--Select--</option>
                            {stateList?.length > 0 &&
                              stateList?.map((state) => {
                                return (
                                  <option value={state.isoCode}>
                                    {state.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Service Location City
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="p-1 form-select rounded-0"
                            name="serviceLocationCity"
                            value={serviceLocationCity}
                            onChange={(e) =>
                              setServiceLocationCity(e.target.value)
                            }
                          >
                            <option value="">Select</option>
                            {cityList?.length > 0 &&
                              cityList?.map((city) => {
                                return (
                                  <option value={city.name}>{city.name}</option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Title <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Plot Length <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="plotLength"
                            value={plotLength}
                            onChange={(e) => setPlotLength(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Plot Width <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="width"
                            value={width}
                            onChange={(e) => setWidth(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Number of bedrooms
                          </label>
                          <input
                            type="number"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="bedroom"
                            value={bedroom}
                            onChange={(e) => setBedroom(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Number of Toilet
                          </label>
                          <input
                            type="number"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="toilet"
                            value={toilet}
                            onChange={(e) => setToilet(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Number of Floor
                          </label>
                          <input
                            type="number"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="floor"
                            value={floor}
                            onChange={(e) => setFloor(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Building Type{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="p-1 form-select rounded-0"
                            name="buildingType"
                            value={buildingType}
                            onChange={(e) => setBuildingType(e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="Residential">Residential</option>
                            <option value="Commercial">Commercial</option>
                            <option value="Industrial">Industrial</option>
                            <option value="Hospital">Hospital</option>
                            <option value="Mixed Use">Mixed Use</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            2D Floor Plan{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="file"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="twoDImage"
                            onChange={(e) => setTwoDImage(e.target.files[0])}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            3D Floor Plan{" "}
                          </label>
                          <input
                            type="file"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="threeDImage"
                            onChange={(e) => setThreeDImage(e.target.files[0])}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            CAD File
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="file"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="cadImage"
                            onChange={(e) => setCadImage(e.target.files[0])}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            CAD Price
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="cadPrice"
                            value={cadPrice}
                            onChange={(e) => setCadPrice(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Design Description
                          </label>
                          <textarea
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary float-end mt-2"
                    >
                      Add
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddDesign;
