import React, { useState } from "react";
import "../Css/login.css";
import { Link } from "react-router-dom";
import { API } from "../API/usersAPI";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [upcomingOtp, setUpcomingOtp] = useState("");

  const VerifyEmail = (e) => {
    e.preventDefault();
    if (email) {
      const data = {
        email: email,
      };
      API.verifyEmail(data).then((resp) => {
        if (resp.status === 200) {
          setUpcomingOtp(resp.otp);
        } else {
          setUpcomingOtp("");
          toast.error(resp.message, {
            position: "top-right",
          });
        }
      });
    } else {
      toast.error("Email required", {
        position: "top-right",
      });
    }
  };

  const VerifyOtp = (e) => {
    e.preventDefault();
    if (otp) {
        const data = {otp:otp}
      API.verifyOtp(data).then((resp) => {
        if (resp.status === 200) {
        }
      });
    } else {
      toast.error("OTP required", {
        position: "top-right",
      });
    }
  };
  return (
    <div className="container-fluid login-sections">
      <ToastContainer />
      <div className="login-container">
        {upcomingOtp ? (
          <form className="login-form" onSubmit={VerifyOtp}>
            <h1 className="text-center">Reset Password</h1>
            <p className="text-center">Verify OTP</p>
            <div className="input-group">
              <input
                type="text"
                id="otp"
                name="otp"
                placeholder="Enter OTP"
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
              />
            </div>
            <button type="submit" className="button-css">
              Submit
            </button>
            <div className="bottom-text">
              <p>
                <Link to="/signup" className="m-1">
                  Sign Up
                </Link>
                <Link to="/login" className="m-1">
                  Login
                </Link>
              </p>
            </div>
          </form>
        ) : (
          <form className="login-form" onSubmit={VerifyEmail}>
            <h1 className="text-center">Reset Password</h1>
            <p className="text-center">Verify email account</p>
            <div className="input-group">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <button type="submit" className="button-css">
              Submit
            </button>
            <div className="bottom-text">
              <p>
                <Link to="/signup" className="m-1">
                 <button type="button" className="btn btn-sm btn-danger">Signup</button>
                </Link>
                <Link to="/login" className="m-1">
                <button type="button" className="btn btn-sm btn-danger">Login</button>
                </Link>
              </p>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
