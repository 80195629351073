import React from "react";
import Sidebar from "../../component/HomeComponents/Sidebar";
import architect1 from "../../img/architect1.webp";
import architect3 from "../../img/architect3.webp"
import architect2 from "../../img/architect2.webp"
import logo1 from "../../img/arch-logo1.jpeg";
import logo2 from "../../img/arch-logo3.jpeg";
import logo3 from "../../img/arch-logo4.jpeg";
import Navbar from "../../common/Navbar"; 
import Footer from "../../component/HomeComponents/Footer";

const Professionals = () => {
  return (
    <>    
    <Navbar /> 
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-10 mt-5 ">
              <div className="row">
                <div className="col-lg-4">
                  <div className="prof-box">
                    <img
                      src={architect1}
                      alt="archtect"
                      className="architect-img"
                    />
                    <div className="arch-des">
                      <div className="row">
                        <div className="col-lg-10">
                          <h6>Stallion Architects</h6>
                          <h6 className="arch-title">HOME OF GRANDESQUE DESIGN</h6>
                        </div>
                        <div className="col-lg-2">
                          <img src={logo1} alt="logo" className="arch-logo" />
                        </div>
                      </div>
                      <hr />
                      <p className="prof-p mt-3">
                        I have been practising in Vastu counsellor and Dowser
                        for 14 years. I have guided more than 2000+ clients with
                        the help of this occult science.
                      </p>
                      <button className="sm-btn">Send Message</button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                <div className="prof-box">
                    <img
                      src={architect2}
                      alt="archtect"
                      className="architect-img"
                    />
                    <div className="arch-des">
                      <div className="row">
                        <div className="col-lg-10">
                          <h6>Space 5 Architects</h6>
                          <h6 className="arch-title">HOME OF GRANDESQUE DESIGN</h6>
                        </div>
                        <div className="col-lg-2">
                          <img src={logo2} alt="logo" className="arch-logo" />
                        </div>
                      </div>
                      <hr />
                      <p className="prof-p mt-3">
                        I have been practising in Vastu counsellor and Dowser
                        for 14 years. I have guided more than 2000+ clients with
                        the help of this occult science.
                      </p>
                      <button className="sm-btn">Send Message</button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                <div className="prof-box">
                    <img
                      src={architect3}
                      alt="archtect"
                      className="architect-img"
                    />
                    <div className="arch-des">
                      <div className="row">
                        <div className="col-lg-10">
                          <h6>Space 5 Architects</h6>
                          <h6 className="arch-title">HOME OF GRANDESQUE DESIGN</h6>
                        </div>
                        <div className="col-lg-2">
                          <img src={logo3} alt="logo" className="arch-logo" />
                        </div>
                      </div>
                      <hr />
                      <p className="prof-p mt-3">
                        I have been practising in Vastu counsellor and Dowser
                        for 14 years. I have guided more than 2000+ clients with
                        the help of this occult science.
                      </p>
                      <button className="sm-btn">Send Message</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Professionals;

