import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CATEGORY_API } from "../../API/addCategoryAPI";
import "../../Reusable Styling/AddItem.sass";
import baseUrl from "../../API/apiUrl";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";

const ViewCategory = () => {
  const params = useParams();
  const [categoryData, setCategoryData] = useState({});

  useEffect(() => {
    CATEGORY_API.getCategoryById(params.id).then((resp) => {
      if (resp.status === 200) {
        // console.log(resp.data)
        setCategoryData(resp.data[0]);
      } else {
        setCategoryData({});
      }
    });
  }, [params]);
  return (
    <>
      <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <div className="add_item_title_div mt-4">
            <h6>View Category</h6>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="add_item_container">
                <div className="add_user_item_div_wrapper">
                  <div className="add_user_item_div">
                    <div className="form_div" style={{ width: "100%" }}>
                      <form>
                        <div className="form_input_div row">
                          <div className="form_input col-lg-6">
                            <label>
                              Category <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              value={categoryData.category}
                              readOnly
                            />
                          </div>
                          <div className="form_input col-lg-6">
                            <label
                              htmlFor="file"
                              className="mt-5"
                            >
                              Image:{" "}
                            </label><br/>
                            <img
                              src={`${baseUrl}/${categoryData.image}`}
                              alt="/"
                              className="w-100 mt-3"
                            />
                          </div>
                        </div>
                        <Link to={`/admin/category/edit/${params.id}`} style={{marginLeft:"200px"}}>
                          <button
                            type="button"
                            className="mt-5"
                            style={{ fontWeight: "bold", color: "white" }}
                          >
                            Edit
                          </button>
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ViewCategory;
