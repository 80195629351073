import React, { useContext, useEffect } from "react";
import logo from "../img/Group-1.png";
import profile from "../img/profile-icon.jpg";
import { NavLink, useLocation, useNavigate, Link } from "react-router-dom";
import "../index.css";
import "../layout/Navbar/Navbar.sass";
import { useState } from "react";
import { API } from "../API/usersAPI";
import { ProfileContext } from "../App";
import imageIcon from "../icon/user-icon.png";
import Nav from "react-bootstrap/Nav";
import Navbar1 from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import {CiMenuFries} from "react-icons/ci";

const Navbar = () => {
  const { user, setUser, estimatePopup, setEstimatePopup } = useContext(ProfileContext);
  const navigate = useNavigate();

  const LogOut = () => {
    localStorage.removeItem("user");
    setUser("");
    navigate("/");
  };
  const navDropdownTitle = (
    <span>
      <img src={imageIcon} width={15} height={15} alt="/" className="me-1" />
      {user?.username}
    </span>
  );
  return (
    <>
      <div className="container-fluid g-0">
        <nav
          className="navbar navbar-expand-lg"
          style={{boxShadow:"0 4px 2px -2px rgba(0,0,0,.2)"}}
        >
          <div className="container-fluid">
            <NavLink className="navbar-brand pe-4" to="/">
              <img src={logo} alt="logo" className="logo-s" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
           <CiMenuFries />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-lg-0">
                <li className="nav-item dropdown">
                  <NavLink className="nav-link dropdown-toggle" to="#" role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Architecture
                  </NavLink>
                  <ul className="dropdown-menu">
                    <li>
                      <NavLink className="dropdown-item bg-light text-dark" to="/architect/professional">
                        Professional
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item bg-light text-dark" to="/architect/packages">
                        Show Packages
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    onClick={() => {
                      setEstimatePopup(!estimatePopup);
                    }}
                    to="/estimate"
                  >
                    Construction
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/interior">
                    Interior
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/bidproject">
                    Bid Project
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/rentproprties">
                    Buy/Sell/Rent of Properties
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/shoponline">
                    Shop Online
                  </NavLink>
                </li>
                {user?._id ? (
                  <li className="nav-item">
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title={navDropdownTitle}
                      menuVariant="light"
                    >
                      <NavDropdown.Item
                        onClick={() => {
                          navigate(
                            `/${user.role.substring(5).toLowerCase()}/profile`
                          );
                        }}
                      >
                        Profile
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={LogOut}>
                        LogOut
                      </NavDropdown.Item>
                    </NavDropdown>
                  </li>
                ) : (
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/login">
                      Login / Register
                    </NavLink>
                  </li>
                )}
              </ul>
              <form className="d-flex" role="search">
                <button className="btn btn-danger" type="button">
                + Book GrahRahi
                </button>
              </form>
            </div>
          </div>
        </nav>

      </div>
    </>
  );
};

export default Navbar;
