import URL from "./apiUrl";

export const ARCHITECT_MANAGE = {
  addPortfolio: async (data) => {
    try {
      const response = await fetch(URL + `architect/application/add`, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  updatePortfolio: async (data, id) => {
    try {
      const response = await fetch(URL + `architect/update-application/${id}`, {
        method: "put",
        body: data,
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  getPortfolioById: async (id) => {
    try {
      const response = await fetch(URL + `architect/details/${id}`, {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  getDesignById: async (id) => {
    try {
      const response = await fetch(URL + `design/details/${id}`, {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  getAllDesign: async () => {
    try {
      const response = await fetch(URL + `design/design-list`, {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  deleteDesignById: async (id) => {
    try {
      const response = await fetch(URL + `design/delete/${id}`, {
        method: "delete",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  updateDesign: async (data, id) => {
    // console.log(data.get("categoryName"))
    try {
      const response = await fetch(`${URL}design/update/${id}`, {
        method: "put",
        body: data,
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
};
