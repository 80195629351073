import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { v4 as uuidv4 } from "uuid";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import ListInTable from "../../Reusable Components/DataTable";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { productListTableColumns } from "./CategoryData";
import "../../Reusable Styling/AddItem.sass";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CATEGORY_API } from "../../API/addCategoryAPI";
import { ProfileContext } from "../../App";

const AddCategories = () => {
  const navigate = useNavigate();
  const { setMessage } = useContext(ProfileContext);
  const [file, setFile] = useState("");
  const [categoryName, setCategoryName] = useState("");
  // const UUID = uuidv4();

  function handleSubmit(e) {
    e.preventDefault();
    if (!categoryName) {
      toast.error("Provide Category Name..");
    } else if (!file) {
      toast.error("Provide Category Image..");
    } else {
      // console.log(categoryName,file)
      const formData = new FormData();
      formData.append("categoryName", categoryName);
      formData.append("file", file);

      CATEGORY_API.addCategory(formData).then(async (resp) => {
        // console.log(resp)
        if (resp.status === 201) {
          setMessage(resp.message);
          setCategoryName("");
          setFile("");
          navigate("/admin/category");
        } else {
          toast.error(resp.message);
        }
      });
    }
  }

  function handleDelete(id) {}

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      headerClassName: "custom_header",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cell_action_div">
            <Link
              to="/users"
              style={{ textDecoration: "none", color: "unset" }}
            >
              <div className="view_btn">View</div>
            </Link>
            <div
              className="delete_btn"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    document.title = "New Products | Admin Dashboard";
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={2000}
        theme="light"
      />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <div className="add_item_title_div mt-4">
            <h6>Add Category</h6>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="add_item_container">
                <div className="add_user_item_div_wrapper">
                  <div className="add_user_item_div">
                    <div className="form_div" style={{ width: "100%" }}>
                      <form onSubmit={handleSubmit}>
                        <div className="form_input_div">
                          <div className="form_input">
                            <label>
                              Category <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              value={categoryName}
                              placeholder="Enter Category..."
                              onChange={(e) => setCategoryName(e.target.value)}
                            />
                          </div>
                          <div className="file_upload_div mt-2">
                            <label
                              htmlFor="file"
                              className="d-flex align-items-center"
                              style={{
                                border: "1px solid black",
                                borderRadius: "5px",
                                padding: "5px",
                                cursor: "pointer",
                              }}
                            >
                              Upload Image:{" "}
                              <DriveFolderUploadOutlinedIcon className="icon mx-1" />
                            </label>
                            <input
                              type="file"
                              id="file"
                              onChange={(e) => setFile(e.target.files[0])}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="mt-5"
                          style={{ fontWeight: "bold", color: "white" }}
                        >
                          Save
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddCategories;
