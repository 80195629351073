import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CATEGORY_API } from "../../API/addCategoryAPI";
import "../../Reusable Styling/AddItem.sass";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";

const UpdateCategory = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    CATEGORY_API.getCategoryById(params.id).then((resp) => {
      if (resp.status === 200) {
        // console.log(resp.data)
        setCategoryName(resp.data[0].category);
      } else {
        setCategoryName("");
      }
    });
  }, [params.id]);

  const updateSubmit = (e) => {
    e.preventDefault();
    if (!categoryName) {
      toast.error("Category required...", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const formData = new FormData();
      formData.append("categoryName", categoryName);
      formData.append("image", image);
      CATEGORY_API.updateCategory(formData,params.id).then((resp) => {
        if (resp.status === 200) {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/admin/category")
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <div className="add_item_title_div mt-4">
            <h6>View Category</h6>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="add_item_container">
                <div className="add_user_item_div_wrapper">
                  <div className="add_user_item_div">
                    <div className="form_div" style={{ width: "100%" }}>
                      <form onSubmit={updateSubmit}>
                        <div className="form_input_div row">
                          <div className="form_input col-lg-6">
                            <label>
                              Category <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="categoryName"
                              value={categoryName}
                              onChange={(e) => setCategoryName(e.target.value)}
                            />
                          </div>
                          <div className="file_upload_div">
                            <label
                              htmlFor="image"
                            >
                              Upload Image:{" "}
                              <DriveFolderUploadOutlinedIcon className="icon mx-1" />
                            </label>
                            <input
                              type="file"
                              id="file"
                              name="image"
                              onChange={(e) => setImage(e.target.files[0])}
                              style={{cursor:"pointer"}}
                            />
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="mt-5"
                          style={{ fontWeight: "bold", color: "white" }}
                        >
                          Update
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UpdateCategory;
