import React, { useContext, useEffect, useState } from "react";
import PopUp from "./PopUp";
import "../../Css/construction.css";
import Navbar from "../../common/Navbar";
import Footer from "../../component/HomeComponents/Footer";
import { MATERIAL_API } from "../../API/materialAPI";
import EstimateModal from "./EstimateModal";
import Consult from "./Consult/Consult";
import Price from "./Price";
import baseUrl from "../../API/apiUrl";
import MaterialCarousel from "./MaterialCarousel";
import materialmage from "../../img/material-container.png";
import { ProfileContext } from "../../App";

const Estimate = ({ setCost }) => {
  const { estimatePopup, setEstimatePopup, estimateData, productCheck ,setProductCheck,setSelect} =
    useContext(ProfileContext);
  const [details, setDetails] = useState({
    building_type: "",
    city: "",
    plotArea: "",
    slabArea: "",
    floors: "",
    foundation: "",
  });
  const [showEstimateModal, setShowEstimateModal] = useState(false);
  const [popupPage,setPopupPage] = useState("");

  // Call Api for get fillup estimate information
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("estimate_data"))) {
      const data = JSON.parse(localStorage.getItem("estimate_data"));
      // console.log(data)
      setDetails({
        ...details,
        building_type: data.building_type,
        city: data.city,
        plotArea: data.plotArea,
        slabArea: data.slabArea,
        floors: data.floors,
        foundation: "",
      });
    }
    else {
      setDetails({
        ...details,
        building_type: "",
        city: "",
        plotArea: "",
        slabArea: "",
        floors: "",
        foundation: "",
      });
    }
  }, []);
  useEffect(() => {
    const productData = productCheck?.filter((item) => item.category?.toLowerCase() === "cement" ||
     item.category?.toLowerCase() === "brick" ||
      item.category?.toLowerCase() === "steel")
      console.log(productData)
      // if(productData?.length ===3){
      //   setShowEstimateModal(!showEstimateModal);
      //   setPopupPage("login");
      // }
  }, [productCheck])

  // Estimate data change function
  const estimateDataChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    const updateStorage = {
      ...JSON.parse(localStorage.getItem("estimate_data")),
      [e.target.name]: e.target.value,
    };
    localStorage.setItem("estimate_data", JSON.stringify(updateStorage));
  };
  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <button
          type="button"
          className="btn btn-danger mt-2 float-end"
          style={{ padding: "5px 5px", marginRight: "38px" }}
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          Get Consultance
        </button>
      </div>
      <div className="container-fluid px-5 pe-5">
        <div className="row mt-5">
          <Consult />
          <div className="col-md-3">
            <label htmlFor="city" className="select-label">
              Select City
            </label>
            <select
              id="city"
              className="form-select"
              name="city"
              value={details.city}
              onChange={estimateDataChange}
              required={true}
            >
              <option defaultValue={details.city}>{details.city}</option>
              <option value="Patna">Patna</option>
              <option value="Noida">Noida</option>
              <option value="Gurgaon">Gurgaon</option>
              <option value="Ranchi">Ranchi</option>{" "}
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="plotArea" className="select-label">
              Slab Area of one floor
            </label>
            <select
              id="plotArea"
              className="form-select"
              name="plotArea"
              value={details.plotArea}
              onChange={estimateDataChange}
              required={true}
            >
              <option value="5000 - 7000">5000 - 7000 Sqft</option>
              <option defaultValue={details.plotArea}>
                {details.plotArea}
              </option>
              <option value="3000 - 5000">3000 - 5000 Sqft</option>
              <option value="2000 - 3000">2000 - 3000 Sqft</option>
              <option value="1000 - 2000">1000 - 2000 Sqft</option>
              <option value="500 - 1000">500 - 1000 Sqft</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="floors" className="select-label">
              No of Floors
            </label>
            <select
              id="floors"
              className="form-select"
              name="floors"
              value={details.floors}
              onChange={estimateDataChange}
              required={true}
            >
              <option value="1">1 (Grounds Floor)</option>
              <option value="2">2 ( G + 1 )</option>
              <option value="3">3 ( G + 2 )</option>
              <option value="4">4 ( G + 3 )</option>
              <option value="5">5 ( G + 4 )</option>
              <option value="6">6 ( G + 5 )</option>
              <option value="7">7 ( G + 6 )</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="foundation" className="select-label">
              Foundation type
            </label>
            <select
              id="floors"
              className="form-select"
              name="foundation"
              value={details.foundation}
              onChange={estimateDataChange}
              required={true}
            >
              <option value="">Choose One</option>
              <option value="Isoloated">Isoloated</option>
              <option value="Pile">Pile</option>
              <option value="Mate">Mate</option>
              <option value="Basement">Basement</option>
            </select>
          </div>
        </div>
      </div>
      {/* <MaterialCarousel /> */}
      <div className="container-fluid px-5 pe-5 mt-5">
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <MaterialCarousel />
            </div>
          </div>
          {/* <div className="col-lg-1"></div> */}
          <div className="col-lg-4 px-5" style={{ display: "flex" }}>
            <div className="line"></div>
            <div className="row mx-2">
              <div className="col-lg-12">
                <p className="unit-cost">Unit Construction Cost</p>
              </div>
              <div className="col-lg-12">
                <span className="unit-cost">Construction Material</span>
                <div className="row mt-4 ">
                  {productCheck?.length > 0 &&
                    productCheck?.map((item, index) => {
                      return (
                        <div className="select-material" key={index}>
                          <div className="col-lg-5 m1">
                            {item.category.toUpperCase()}
                          </div>
                          <div className="col-lg-7 d1">
                            <span style={{ fontSize: "12px", color: "black" }}>
                              {item.name.toUpperCase()}
                            </span>
                            <br />
                            {item.descriptionOne && (
                              <span>{item.descriptionOne}</span>
                            )}
                            <br />
                            {item.descriptionTwo && (
                              <span>{item.descriptionTwo}</span>
                            )}
                            <br />
                            {item.descriptionThree && (
                              <span>{item.descriptionThree}</span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {estimatePopup === true && <PopUp />}
      {showEstimateModal ? (
        <EstimateModal
          showEstimateModal={showEstimateModal}
          setShowEstimateModal={setShowEstimateModal}
          setCost={setCost}
          popupPage={popupPage}
          setPopupPage={setPopupPage}
          setProductCheck={setProductCheck}
          setSelect={setSelect}
        />
      ) : (
        ""
      )}
      <Footer />
    </>
  );
};

export default Estimate;
