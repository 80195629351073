import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ProfileContext } from "../../App";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import ListInTable from "../../Reusable Components/DataTable";
import "../../App.sass";
import "./style.css";
import { CATEGORY_API } from "../../API/addCategoryAPI";
import baseUrl from "../../API/apiUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CategoriesList = () => {
  var arrayData = [];
  const userColumns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "custom_header",
      width: 250,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.id}</div>;
      },
    },
    {
      field: "category",
      headerName: "Name",
      headerClassName: "custom_header",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cell_img_div">
            <img
              className="cell_img me-2"
              src={`${baseUrl}${params.row.image}`}
              alt="avatar"
            />
            {params.row.category}
          </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            className={`cell_status_div px-2 py-1 ${
              params.row.status === "Active"
                ? "active"
                : params.row.status === "Pending"
                ? "pending"
                : "passive"
            }`}
          >
            {params.row.status}
          </div>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);

  const handleDelete = (id)=> {
    if (window.confirm("Are you sure want to delete?")) {
      CATEGORY_API.deleteCategoryById(id).then((resp) => {
        if (resp.status === 200) {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          getAllCategoeyList();
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  }
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      headerClassName: "custom_header",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cell_action_div">
            <Link
              to={`/admin/category/view/${params.row.id}`}
              style={{ textDecoration: "none", color: "unset" }}
              className="view_btn"
            >
              View
            </Link>
            <div
              className="delete_btn"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    document.title = "Users | Admin Dashboard";
    getAllCategoeyList();
  }, []);

  const getAllCategoeyList = () => {
    CATEGORY_API.getAllCategory().then((resp) => {
      if (resp.status === 200) {
        // console.log(resp.data);
        setRows(resp.data);
      } else {
        setRows([]);
      }
    });
  };

  rows?.map((item) => {
    return arrayData?.push({
      id: item._id,
      image: item.image,
      category: item.category,
      status: item.status,
    });
  });

  return (
    <>
      <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <UserTable className="users_list_container">
            <div className="users_list_container_title">
              <h4 className="p-2 mb-0">Category List</h4>
              <Link to="/admin/add-category">
                <button type="button" className="btn btn-secondary me-2">
                  Add Category
                </button>
              </Link>
            </div>
            <ListInTable
              rows={arrayData}
              columns={userColumns.concat(actionColumn)}
            />
          </UserTable>
        </div>
      </main>
    </>
  );
};

export const UserTable = styled.div`
  z-index: 0;
  /* Resetting MUI table color props */
  p,
  div.MuiTablePagination-actions > button {
    color: inherit;
  }
  /* END */
`;

export default CategoriesList;
