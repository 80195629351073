import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../layout/Sidebar/Sidebar";
import Navbar from "../layout/Navbar/Navbar";
import "../Reusable Styling/AddItem.sass";
import "../Pages/AdminPages/style.css";
import {ProfileContext } from "../App";
import { API } from "../API/usersAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";


const ProfileEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
    const { user } = useContext(ProfileContext);
  const [file, setFile] = useState("");
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [activeUser, setActiveUser] = useState({});

  useEffect(()=>{
    getActiveUserDetail(params?.id);
  },[])

  const getActiveUserDetail=(id)=>{
    API.getUserById(id).then((resp)=>{
        if(resp.status===200){
          // console.log(resp.data)
            setActiveUser(resp.data[0])
            setFile(resp.data[0].profileImage);
            setUserName(resp.data[0].username);
            setFirstName(resp.data[0].firstname);
            setLastName(resp.data[0].lastname);
            setEmail(resp.data[0].email);
            setPhone(resp.data[0].phone);
        }
        else{
            setActiveUser({});
        }
    })
  }

  function UpdateField(e) {
    e.preventDefault();
      if (!userName) {
        toast.error("Username is required",{
          position:"top-right"
        })
      }
      else if (!email) {
        toast.error("Email is required",{
          position:"top-right"
        })
      }
      else if (!phone) {
        toast.error("Phone is required",{
          position:"top-right"
        })
      }
      else if (isNaN(phone)) {
        toast.error("Phone number must be number.",{
            position:"top-right"
          })
      } 
      else {
        // console.log(file,userName,fullName,email,phone)
        const formData = new FormData();
        formData.append("file",file);
        formData.append("userName",userName);
        formData.append("firstName",firstName);
        formData.append("lastName",lastName);
        formData.append("email",email);
        formData.append("phone",phone);

        API.updateProfile(formData,user?._id).then((resp)=>{
          if(resp.status === 200){
            toast.success(resp.message,{
              position:"top-right"
            })
            setUserName("");
            setFirstName("");
            setLastName("");
            setEmail("");
            setPhone("");
            setFile("");
            navigate(`/${user?.role.substring(5).toLowerCase()}/profile`)
          }
          else{
            toast.error("Error while update profile",{
              position:"top-right"
            })
          }
        })
      }
  }
  return (
    <>
    <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <div className="add_item_title_div">
            <h6>Profile Details</h6>
          </div>
          <form
            className="add_item_title_div mt-3 pt-5 pb-5"
            onSubmit={UpdateField}
          >
            <div className="row">
              <div className="col-lg-8 mx-auto d-block mt-3">
                <div className="row">
                  <div className="text-center">
                      <input type="file" name="file" className="border-0" onChange={(e) => setFile(e.target.files[0])} />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-6">
                    <label>Username</label>
                    <input
                      className="myReadonlyInput read-profile"
                      type="text"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid black",
                      }}
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="myReadonlyInput"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid black",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-8 mx-auto d-block">
                <div className="row">
                <div className="col-lg-6">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="myReadonlyInput"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid black",
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>Email</label>
                    <input
                      type="mail"
                      className="myReadonlyInput read-profile"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid black",
                      }}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row mt-5">
                <div className="col-lg-6">
                    <label>Phone no:</label>
                    <input
                      type="text"
                      className="myReadonlyInput"
                      inputMode="numeric"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid black",
                      }}
                    />
                  </div>
                </div>
                <div className="row float-end">
                  <button
                    type="submit"
                    className="mt-4 btn-success"
                    style={{ padding: "0.4rem 1.6rem" }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default ProfileEdit;
