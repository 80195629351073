import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import ListInTable from "../../Reusable Components/DataTable";
import "../../App.sass";
import "../AdminPages/style.css";
import { ProfileContext } from "../../App";

const MyNotifications = () => {
  const { user } = useContext(ProfileContext);
  var arrayData = [];
  const userColumns = [
    
    {
      field: "message",
      headerName: "Message",
      headerClassName: "custom_header",
      width: 500,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.message}</div>;
      },
    },
    {
      field: "time",
      headerName: "TimeStamp",
      headerClassName: "custom_header",
      width: 350,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.time}</div>;
      },
    },
  ];

  const [rows, setRows] = useState([]);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      headerClassName: "custom_header",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cell_action_div">
            <Link
              to="#"
              style={{ textDecoration: "none", color: "unset" }}
              className="view_btn"
            >
             View
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    document.title = "MyGriha | Dealer Notification";
  }, []);

  rows?.map((item) => {
    return arrayData?.push({
      id: item._id,
      message: item.message,
      time: item.time,
    });
  });

  return (
    <>
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <UserTable className="users_list_container">
            <div className="users_list_container_title">
              <h4 className="p-2 mb-0">My Orders</h4>
            </div>
            <div>
              <ListInTable
                rows={arrayData}
                columns={userColumns.concat(actionColumn)}
              />
            </div>
          </UserTable>
        </div>
      </main>
    </>
  );
};

export const UserTable = styled.div`
  z-index: 0;
  /* Resetting MUI table color props */
  p,
  div.MuiTablePagination-actions > button {
    color: inherit;
  }
  /* END */
`;

export default MyNotifications;
