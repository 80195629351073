import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import ListInTable from "../../Reusable Components/DataTable";
import "../../App.sass";
import "../AdminPages/style.css";
import { PRODUCT_API } from "../../API/addProductAPI";
import baseUrl from "../../API/apiUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DESIGN_API } from "../../API/designAPI";
import { Button } from "@mui/material";
import { ProfileContext } from "../../App";

const MyDesigns = () => {
  const { user } = useContext(ProfileContext);
  var arrayData = [];
  const userColumns = [
    {
      field: "id",
      headerName: "Id",
      headerClassName: "custom_header",
      width: 230,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.id}</div>;
      },
    },
    {
      field: "title",
      headerName: "Building Name",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.title}</div>;
      },
    },
    {
      field: "buildingType",
      headerName: "Building Type",
      headerClassName: "custom_header",
      width: 180,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.buildingType}</div>;
      },
    },
    {
      field: "area",
      headerName: "Plot Length * Width",
      headerClassName: "custom_header",
      width: 180,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.area}</div>;
      },
    },
    {
      field: "floor",
      headerName: "No of Floor",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.floor}</div>;
      },
    },
    {
      field: "bedroom",
      headerName: "No of Bedroom",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.bedroom}</div>;
      },
    },
    {
      field: "toilet",
      headerName: "No of Toilet",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.toilet}</div>;
      },
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "custom_header",
      width: 300,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.description}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            className={`cell_status_div px-2 py-1 ${
              params.row.status === "Approved"
                ? "Approved"
                : params.row.status === "Pending"
                ? "Pending"
                : "Rejected"
            }`}
          >
            {params.row.status}
          </div>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);

  function handleDelete(id) {
    if (window.confirm("Are you sure want to delete?")) {
      DESIGN_API.deleteDesignById(id).then((resp) => {
        if (resp.status === 200) {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          getAllDesignList();
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  }
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      headerClassName: "custom_header",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cell_action_div">
            <Link
              to={`/architect/design/view/${params.row.id}`}
              style={{ textDecoration: "none", color: "unset" }}
              className="view_btn"
            >
              View
            </Link>
            <div
              className="delete_btn"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    document.title = "Design | Architect design";
    getAllDesignList();
  }, []);

  const getAllDesignList = () => {
    DESIGN_API.getDesignByUser(user?._id).then((resp) => {
      if (resp.status === 200) {
        setRows(resp.data);
      } else {
        setRows([]);
      }
    });
  };

  rows?.map((item) => {
    return arrayData?.push({
      id: item._id,
      title: item.title,
      buildingType: item.buildingType,
      area: item.plotLength * item.width,
      floor: item.floor,
      bedroom: item.bedroom,
      toilet: item.toilet,
      description: item.description,
      status: item.status,
    });
  });

  return (
    <>
      <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <UserTable className="users_list_container">
            <div className="users_list_container_title">
              <h4 className="p-2 mb-0">All Designs</h4>
              <Link to="/architect/designs-upload" className="me-2">
                <Button variant="contained" size="small" color="success">
                  Add Design
                </Button>
              </Link>
            </div>
            <div>
              <ListInTable
                rows={arrayData}
                columns={userColumns.concat(actionColumn)}
              />
            </div>
          </UserTable>
        </div>
      </main>
    </>
  );
};

export const UserTable = styled.div`
  z-index: 0;
  /* Resetting MUI table color props */
  p,
  div.MuiTablePagination-actions > button {
    color: inherit;
  }
  /* END */
`;

export default MyDesigns;
