import React from "react";
import "../../Css/menu.css";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import icon1 from "../../icon/Architecture.png";
import icon2 from "../../icon/Construction.png";
import icon3 from "../../icon/Interior-1.png";
import icon4 from "../../icon/Calculator.png";
import icon5 from "../../icon/Bid-Project.png";
import icon6 from "../../icon/Post-your-project.png";
import icon7 from "../../icon/Furniture.png";
import icon8 from "../../icon/Vastu-Consultant....png";
import icon9 from "../../icon/Design-Library.png";
import icon10 from "../../icon/Architech.png";
import icon11 from "../../icon/Blogs.png";
import icon12 from "../../icon/Queries.png";
import icon13 from "../../icon/Booking-red.png";
import icon14 from "../../icon/Resoursing.png";
import icon15 from "../../icon/Professnal.png";
import icon16 from "../../icon/Deal.png";
import icon17 from "../../icon/Outsourcing.png";
import icon18 from "../../icon/About-us.png";
import icon19 from "../../icon/Contact-Us.png";
import icon20 from "../../icon/Design-Cour.png";
import icon21 from "../../icon/Design-Lib.png";
import icon22 from "../../icon/dicon3.png";

const Menu = ({ showPopup, setShowPopup }) => {
  $(".sidebar-dropdown > NavLink").click(function () {
    $(".sidebar-submenu").slideUp();
    if ($(this).parent().hasClass("active")) {
      $(".sidebar-dropdown").removeClass("active");
      $(this).parent().removeClass("active");
    } else {
      $(".sidebar-dropdown").removeClass("active");
      $(this).next(".sidebar-submenu").slideDown(200);
      $(this).parent().addClass("active");
    }
  });

  return (
    <>
      <>
        <div className="page-wrapper chiller-theme toggled">
          <nav id="sidebar" className="sidebar-wrapper">
            <div className="sidebar-content">
              {/* sidebar-search  */}
              <div className="sidebar-menu">
                <ul>
                  <li className="header-menu">
                    <p>Menu</p>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon1} alt="" className="s-icon" />
                      <span className="title2">Architecture</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <NavLink href="/architect/packages">
                            Show Packages
                          </NavLink>
                        </li>
                        <li>
                          <NavLink href="/architect/professional">
                            Professional
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdowns">
                    <NavLink
                      onClick={() => setShowPopup(!showPopup)}
                      className="nav-link"
                      to="/estimate"
                    >
                      <img src={icon2} alt="" className="s-icon" />
                      <span className="title2">Construction</span>
                    </NavLink>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon3} alt="" className="s-icon" />
                      <span className="title2">Interior</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">General</a>
                        </li>
                        <li>
                          <a href="#">Panels</a>
                        </li>
                        <li>
                          <a href="#">Tables</a>
                        </li>
                        <li>
                          <a href="#">Icons</a>
                        </li>
                        <li>
                          <a href="#">Forms</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon22} alt="" className="s-icon" />
                      <span className="title2">Buy/Sell/Rent</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Pie chart</a>
                        </li>
                        <li>
                          <a href="#">Line chart</a>
                        </li>
                        <li>
                          <a href="#">Bar chart</a>
                        </li>
                        <li>
                          <a href="#">Histogram</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon4} alt="" className="s-icon" />
                      <span className="title2">Calculator</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon5} alt="" className="s-icon" />
                      <span className="title2">Bid Project</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon6} alt="" className="s-icon" />
                      <span className="title2">Post your Project</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon7} alt="" className="s-icon" />
                      <span className="title2">Furniture & Decor</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon8} alt="" className="s-icon" />
                      <span className="title2">Vastu Consultant</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon20} alt="" className="s-icon" />
                      <span className="title2">Courses</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon9} alt="" className="s-icon" />
                      <span className="title2">Design Idea</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon21} alt="" className="s-icon" />
                      <span className="title2">Design Library</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon10} alt="" className="s-icon" />
                      <span className="title2">Forem/Architect</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon11} alt="" className="s-icon" />
                      <span className="title2">Blog</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon12} alt="" className="s-icon" />
                      <span className="title2">Queries/Help Me</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon13} alt="" className="s-icon" />
                      <span className="title2">Book Consultant</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon14} alt="" className="s-icon" />
                      <span className="title2">Resources</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon15} alt="" className="s-icon" />
                      <span className="title2">Professional</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon16} alt="" className="s-icon" />
                      <span className="title2">Become GrahRahi</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon17} alt="" className="s-icon" />
                      <span className="title2">Outsourcing</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon18} alt="" className="s-icon" />
                      <span className="title2">About Us</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <NavLink className="nav-link" to="#">
                      <img src={icon19} alt="" className="s-icon" />
                      <span className="title2">Contact Us</span>
                    </NavLink>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <a href="#">Google maps</a>
                        </li>
                        <li>
                          <a href="#">Open street map</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </>
    </>
  );
};

export default Menu;
