import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CATEGORY_API } from "../../API/addCategoryAPI";
import "../../Reusable Styling/AddItem.sass";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import baseUrl from "../../API/apiUrl";
import { PRODUCT_API } from "../../API/addProductAPI";
import { ProfileContext } from "../../App";
import { State, City } from "country-state-city";

const UpdateProduct = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useContext(ProfileContext);
  const [category, setCategory] = useState("");
  const [serviceLocationState, setServiceLocationState] = useState("");
  const [serviceLocationCity, setServiceLocationCity] = useState("");
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [descriptionOne, setDescriptionOne] = useState("");
  const [descriptionTwo, setDescriptionTwo] = useState("");
  const [descriptionThree, setDescriptionThree] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    document.title = "Update Products | Admin Dashboard";
    getAllCategoeyList();
    setStateList(State.getStatesOfCountry("IN"));
    PRODUCT_API.getProductById(params.id).then((resp) => {
      if (resp.status === 200) {
        // console.log(resp.data)
        setServiceLocationState(resp.data[0].serviceLocationState);
        setServiceLocationCity(resp.data[0].serviceLocationCity);
        setCategory(resp.data[0].category);
        setName(resp.data[0].name);
        setPrice(resp.data[0].price);
        setUnit(resp.data[0].unit);
        setQuantity(resp.data[0].quantity);
        setDescriptionOne(resp.data[0].descriptionOne);
        setDescriptionTwo(resp.data[0].descriptionTwo);
        setDescriptionThree(resp.data[0].descriptionThree);
        setPhoto(resp.data[0].photo);
      }
      if(resp.data[0].serviceLocationState){
        setCityList(
          City.getCitiesOfState("IN", resp.data[0].serviceLocationState)
        );
      }
      else{
        setCityList([])
      }
    });
  }, [params.id]);
  const getAllCategoeyList = () => {
    CATEGORY_API.getAllCategory().then((resp) => {
      if (resp.status === 200) {
        setCategoryList(resp.data);
      } else {
        setCategoryList([]);
      }
    });
  };

  const updateProductSubmit = (e) => {
    e.preventDefault();
    if (!serviceLocationState) {
      toast.error("Service location state required..", {
        position: "top-right",
      });
    } else if (!serviceLocationCity) {
      toast.error("Service location city required..", {
        position: "top-right",
      });
    } else if (!category) {
      toast.error("Product category required..", {
        position: "top-right",
      });
    } else if (!name) {
      toast.error("Product name required..", {
        position: "top-right",
      });
    } else if (!photo) {
      toast.error("Product image required..", {
        position: "top-right",
      });
    } else if (!quantity) {
      toast.error("Minimum product quantity required..");
    } else if (!unit) {
      toast.error("Product measure unit required..");
    } else if (!price) {
      toast.error("Product price required..", {
        position: "top-right",
      });
    } else if (!descriptionOne) {
      toast.error("Product description required..", {
        position: "top-right",
      });
    } else {
      const formData = new FormData();
      formData.append("serviceLocationState", serviceLocationState);
      formData.append("serviceLocationCity", serviceLocationCity);
      formData.append("category", category);
      formData.append("name", name);
      formData.append("photo", photo);
      formData.append("quantity", quantity);
      formData.append("unit", unit);
      formData.append("price", price);
      formData.append("descriptionOne", descriptionOne);
      formData.append("descriptionTwo", descriptionTwo);
      formData.append("descriptionThree", descriptionThree);

      PRODUCT_API.updateProduct(formData, params.id).then((resp) => {
        if (resp.status === 200) {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/admin/product");
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <div className="add_item_title_div mt-4">
            <h6>Update Product</h6>
          </div>
          <div className="cards_container">
            <div className="profile_container">
              <form onSubmit={updateProductSubmit}>
                <div className="row m-5">
                  <div className="col-md-6 ">
                    <label htmlFor="profile">
                      Service Location State
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select "
                      name="serviceLocationState"
                      value={serviceLocationState}
                      onChange={(e) => {
                        setServiceLocationState(e.target.value);
                        setCityList(
                          City.getCitiesOfState("IN", e.target.value)
                        );
                      }}
                    >
                      <option value="">Select State</option>
                      {stateList?.length > 0 &&
                        stateList?.map((state, index) => {
                          return (
                            <option key={index} value={state.isoCode}>
                              {state.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Service Location City
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select "
                      name="serviceLocationCity"
                      value={serviceLocationCity}
                      onChange={(e) => setServiceLocationCity(e.target.value)}
                    >
                      <option value="">Select City</option>
                      {cityList?.length > 0 &&
                        cityList?.map((city, index) => {
                          return (
                            <option key={index} value={city.name}>
                              {city.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Product Category
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select "
                      name="category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      {categoryList?.length > 0 &&
                        categoryList?.map((item, index) => {
                          return (
                            <option key={index} value={item.category}>
                              {item.category}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Photo<span style={{ color: "red" }}>*</span>
                    </label>
                    {photo ? (
                      <img
                        src={`${baseUrl}/${photo}`}
                        alt="/"
                        className="mt-3 mx-2"
                        style={{ width: "50px", height: "50px" }}
                      />
                    ) : (
                      ""
                    )}
                    <input
                      type="file"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="image"
                      onChange={(e) => setPhoto(e.target.files[0])}
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="profile"
                      value={name}
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Quantity<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      id="profile"
                      name="quantity"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Unit<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="profile"
                      name="unit"
                      value={unit}
                      onChange={(e) => setUnit(e.target.value)}
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Price
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      id="profile"
                      name="price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Description One<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="profile"
                      name="descriptionOne"
                      value={descriptionOne}
                      onChange={(e) => setDescriptionOne(e.target.value)}
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">Description Two</label>
                    <input
                      type="text"
                      id="profile"
                      name="descriptionTwo"
                      value={descriptionTwo}
                      onChange={(e) => setDescriptionTwo(e.target.value)}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">Description Three</label>
                    <input
                      type="text"
                      id="profile"
                      name="descriptionThree"
                      value={descriptionThree}
                      onChange={(e) => setDescriptionThree(e.target.value)}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary float-end m-5">
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UpdateProduct;
