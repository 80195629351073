import React, { useEffect, useContext, useState } from "react";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import "../../App.sass";
import "../Home/Home.sass";
import "../AdminPages/style.css";
import { ProfileContext } from "../../App";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API } from "../../API/usersAPI";
import baseUrl from "../../API/apiUrl";
import { ToastContainer,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const { user} = useContext(ProfileContext);
  const params = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    username: "",
    email: "",
    firstname: "",
    lastname: "",
    profileImage: "",
    phone: "",
  });

  useEffect(() => {
    document.title = "MyGriha | Dealer Dashboard";
  }, []);
  useEffect(() => {
    API.getUserById(user?._id).then((resp) => {
      if (resp.status === 200) {
        setUserDetails({
          firstname: resp.data[0].firstname ? resp.data[0].firstname : "",
          lastname: resp.data[0].lastname ? resp.data[0].lastname : "",
          profileImage: resp.data[0].profileImage
            ? resp.data[0].profileImage
            : "",
          username: resp.data[0].username ? resp.data[0].username : "",
          email: resp.data[0].email ? resp.data[0].email : "",
          phone: resp.data[0].phone ? resp.data[0].phone : "",
        });
      }
    });
  }, [user?._id,params?.id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      setUserDetails({ ...userDetails, [name]: e.target.files[0] });
    } else {
      setUserDetails({ ...userDetails, [name]: value });
    }
  };

  const profileUpdate = (e)=>{
    e.preventDefault();
    const formData = new FormData();
    formData.append("firstname",userDetails.firstname);
    formData.append("lastname",userDetails.lastname);
    formData.append("phone",userDetails.phone);
    formData.append("profileImage",userDetails.profileImage);
    API.updateProfile(formData,params?.id).then((resp)=>{
      if(resp.status === 200){
        toast.success(resp.message,{
          position:"top-right"
        })
        navigate("/architect");
      }
      else{
        toast.error(resp.message,{
          position:"top-right"
        })
      }
    })
  }

  return (
    <>
    <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel" id="report_page">
          <Navbar />
          <div className="cards_container">
            <div className="profile_title">
              <h5 className="p-2 mb-0">
                {params?.id ? "Update Profile" : "Profile Details"}
              </h5>
            </div>
          </div>
          {params?.id ? (
            <div className="cards_container">
              <div className="profile_container">
                <form onSubmit={profileUpdate}>
                  <div className="row m-5">
                    <div className="col-md-6">
                      <label htmlFor="profile">Profile Pic</label>
                      <input
                        type="file"
                        id="profile"
                        name="profileImage"
                        onChange={handleChange}
                        className="profile_input"
                      />
                    </div>
                    <div className="col-md-6 ">
                      <label htmlFor="profile">First Name</label>
                      <input
                        type="text"
                        placeholder={
                          userDetails?.firstname
                            ? userDetails.firstname
                            : "First Name"
                        }
                        id="profile"
                        name="firstname"
                        onChange={handleChange}
                        value={userDetails.firstname}
                        className="profile_input"
                      />
                    </div>
                  </div>
                  <div className="row m-5">
                    <div className="col-md-6">
                      <label htmlFor="profile">Last Name</label>
                      <input
                        type="text"
                        placeholder={
                          userDetails?.lastname
                            ? userDetails.lastname
                            : "Last Name"
                        }
                        id="profile"
                        name="lastname"
                        onChange={handleChange}
                        value={userDetails.lastname}
                        className="profile_input"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile">User Name</label>
                      <input
                        type="text"
                        placeholder={
                          userDetails?.username
                            ? userDetails.username
                            : "User Name"
                        }
                        id="profile"
                        name="username"
                        onChange={handleChange}
                        value={userDetails.username}
                        className="profile_input"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row m-5">
                    <div className="col-md-6">
                      <label htmlFor="profile">Email</label>
                      <input
                        type="text"
                        placeholder={
                          userDetails?.email ? userDetails.email : "Email"
                        }
                        id="profile"
                        name="email"
                        onChange={handleChange}
                        value={userDetails.email}
                        className="profile_input"
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile">Phone</label>
                      <input
                        type="text"
                        placeholder={
                          userDetails?.phone
                            ? userDetails.phone
                            : "Phone Number"
                        }
                        id="profile"
                        name="phone"
                        onChange={handleChange}
                        value={userDetails.phone}
                        className="profile_input"
                      />
                    </div>
                  </div>
                  {params.id ? (
                    <button
                      type="submit"
                      className="btn btn-success float-end m-5"
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success float-end m-5"
                    >
                      <Link
                        className="text-light text-decoration-none"
                        to={`/dealer/edit-profile/${user?._id}`}
                      >
                        Edit
                      </Link>
                    </button>
                  )}
                </form>
              </div>
            </div>
          ) : (
            <div className="cards_container">
              <div className="profile_container">
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">Profile Pic</label>
                    <br />
                    {userDetails.profileImage ? (
                      <img
                        src={`${baseUrl}/${userDetails.profileImage}`}
                        alt="document"
                        className="img-fluid mt-2"
                        style={{ width: "20%", height: "100px" }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 ">
                    <label htmlFor="profile">First Name</label>
                    <input
                      type="text"
                      placeholder={
                        userDetails?.firstname
                          ? userDetails.firstname
                          : "First Name"
                      }
                      id="profile"
                      name="firstname"
                      value={userDetails.firstname}
                      onChange={handleChange}
                      className="profile_input"
                      disabled
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">Last Name</label>
                    <input
                      type="text"
                      placeholder={
                        userDetails?.lastname
                          ? userDetails.lastname
                          : "Last Name"
                      }
                      id="profile"
                      name="lastname"
                      value={userDetails.lastname}
                      onChange={handleChange}
                      className="profile_input"
                      disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">User Name</label>
                    <input
                      type="text"
                      placeholder={
                        userDetails?.username
                          ? userDetails.username
                          : "User Name"
                      }
                      id="profile"
                      name="username"
                      value={userDetails.username}
                      className="profile_input"
                      disabled
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">Email</label>
                    <input
                      type="text"
                      placeholder={
                        userDetails?.email ? userDetails.email : "Email"
                      }
                      id="profile"
                      name="email"
                      value={userDetails.email}
                      className="profile_input"
                      disabled
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">Phone</label>
                    <input
                      type="text"
                      placeholder={
                        userDetails?.phone ? userDetails.phone : "Phone Number"
                      }
                      id="profile"
                      name="phone"
                      value={userDetails.phone}
                      onChange={handleChange}
                      className="profile_input"
                      disabled
                    />
                  </div>
                </div>
                {params.id ? (
                  <button
                    type="submit"
                    className="btn btn-success float-end m-5"
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-success float-end m-5"
                  >
                    <Link
                      className="text-light text-decoration-none"
                      to={`/dealer/edit-profile/${user?._id}`}
                    >
                      Edit
                    </Link>
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default Home;
