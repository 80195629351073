import React, { useContext, useEffect, useState } from "react";
import "../../Css/Carousel.css";
import { CATEGORY_API } from "../../API/addCategoryAPI";
import baseUrl from "../../API/apiUrl";
import { ProfileContext } from "../../App";
import { PRODUCT_API } from "../../API/addProductAPI";

const MaterialCarousel = () => {
  const { select, setSelect, productCheck, setProductCheck } =
    useContext(ProfileContext);
  const [category, setCategory] = useState([]);
  const [product, setProduct] = useState([]);
  useEffect(() => {
    CATEGORY_API.getAllCategory().then((resp) => {
      // console.log(resp.data)
      setCategory(resp.data);
    });
    PRODUCT_API.getAllProduct().then((resp) => {
      setProduct(
        resp.data.filter(
          (item) => item.category.toLowerCase() === select.toLowerCase()
        )
      );
    });
  }, [select]);

  const changeMaterial = (material) => {
    setSelect(material);
    PRODUCT_API.getAllProduct().then((resp) => {
      // console.log(
      //   resp.data.filter(
      //     (item) => item.category.toLowerCase() === material.toLowerCase()
      //   )
      // );
      setProduct(
        resp.data.filter(
          (item) => item.category.toLowerCase() === material.toLowerCase()
        )
      );
    });
  };

  const productCheckFunction = (item) => {
    if (productCheck.length > 0) {
      for (let i = 0; i < productCheck?.length; i++) {
        if (
          productCheck[i].category.toLowerCase() ===
          item.category.toLowerCase() &&
          productCheck[i].name.toLowerCase() ===
          item.name.toLowerCase()
        ) {
          productCheck.splice(i, 1);
          setProductCheck([...productCheck]);
        } else if (
          productCheck[i].category.toLowerCase() ===
          item.category.toLowerCase() &&
          productCheck[i].name.toLowerCase() !==
          item.name.toLowerCase()
        ) {
          productCheck.splice(i, 1);
          setProductCheck([...productCheck, item]);
        } else {
          setProductCheck([...productCheck, item]);
        }
      }
    } else {
      setProductCheck([...productCheck, item]);
    }
  };
  return (
    <>
      <div className="col-lg-12 col-md-12 ">
        <div className="row">
          {category?.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  select === item.category.toLowerCase()
                    ? "card material material-active col-lg-2 col-md-2 col-sm-4 col-xsm-4"
                    : "card material col-lg-2 col-md-2 col-sm-4 col-xsm-4"
                }
                onClick={() => changeMaterial(item.category.toLowerCase())}
              >
                {item.category?.toLowerCase() === "steel" ? <span className="bg-danger">Mandatory</span>
                  : item.category.toLowerCase() === "cement" ? <span className="bg-danger">Mandatory</span>
                    : item.category.toLowerCase() === "brick" ? <span className="bg-danger">Mandatory</span>
                      : <span className="bg-danger">Recommended</span>}
                <img
                  src={`${baseUrl}${item.image}`}
                  alt="material"
                  className="w-100 img-fluid material-img"
                  style={{ height: "8rem" }}
                />
                <p className="fw-bold" style={{fontSize:"12px"}}>{item.category.toUpperCase()}</p>
              </div>
            );
          })}
          <hr className="mt-3" />
        </div>
      </div>
      <section>
        <div className="col-lg-12 mt-3">
          <div className="row">
            {product?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    productCheck?.filter(
                      (chk) =>
                        chk.name.toLowerCase() ===
                        item.name.toLowerCase()
                    ).length > 0
                      ? "col-lg-2 col-md-2 col-sm-3 product check-product"
                      : "col-lg-2 col-md-2 col-sm-3 product"
                  }
                  onClick={() => productCheckFunction(item)}
                >
                  <img
                    src={`${baseUrl}${item.photo}`}
                    alt="product"
                    className="w-100 img-fluid"
                    style={{ height: "6rem" }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default MaterialCarousel;
