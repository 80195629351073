import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../layout/Sidebar/Sidebar";
import Navbar from "../layout/Navbar/Navbar";
import "../Reusable Styling/AddItem.sass";
import "../Pages/AdminPages/style.css";
import {ProfileContext } from "../App";
import { API } from "../API/usersAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import baseUrl from "../API/apiUrl";


const ProfileView = () => {
  const navigate = useNavigate();
  const params = useParams();
    const { user } = useContext(ProfileContext);
  const [file, setFile] = useState("");
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [activeUser, setActiveUser] = useState({});

  useEffect(()=>{
    getActiveUserDetail(params?.id);
  },[])

  const getActiveUserDetail=(id)=>{
    API.getUserById(id).then((resp)=>{
        if(resp.status===200){
          // console.log(resp.data)
            setActiveUser(resp.data[0])
            setFile(resp.data[0].profileImage);
            setUserName(resp.data[0].username);
            setFirstName(resp.data[0].firstname);
            setLastName(resp.data[0].lastname);
            setEmail(resp.data[0].email);
            setPhone(resp.data[0].phone);
        }
        else{
            setActiveUser({});
        }
    })
  }
  return (
    <>
    <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <div className="add_item_title_div">
            <h6>User Details</h6>
          </div>
          <form
            className="add_item_title_div pt-5 pb-5"
          >
            <div className="row">
              <div className="col-lg-8 mx-auto d-block mt-3">
                <div className="row">
                  <div className="text-center">
                      <img
                        width={100}
                        height={100}
                        src={
                          activeUser?.profileImage
                            ? `${baseUrl}${activeUser?.profileImage}`
                            : require("../Image/no_img.png")
                        }
                        alt="Upload"
                      />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-6">
                    <label>Username</label>
                    <input
                      className="myReadonlyInput read-profile"
                      type="text"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid black",
                      }}
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="myReadonlyInput read-profile"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      readOnly
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid black",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-8 mx-auto d-block">
                <div className="row">
                <div className="col-lg-6">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="myReadonlyInput read-profile"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      readOnly
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid black",
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>Email</label>
                    <input
                      type="mail"
                      className="myReadonlyInput read-profile"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      readOnly
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid black",
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-5">
                <div className="col-lg-6">
                    <label>Phone no:</label>
                    <input
                      type="text"
                      className="myReadonlyInput read-profile"
                      inputMode="numeric"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      readOnly
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid black",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default ProfileView;
