import URL from "./apiUrl";

export const DEALER_MANAGE = {
  addPortfolio: async (data) => {
    try {
      const response = await fetch(URL + `dealer/application/add`, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  updatePortfolio: async (data, id) => {
    try {
      const response = await fetch(URL + `dealer/update-application/${id}`, {
        method: "put",
        body: data,
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  getPortfolioById: async (id) => {
    try {
      const response = await fetch(URL + `dealer/details/${id}`, {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  getProductById: async (id) => {
    try {
      const response = await fetch(URL + `product/details/${id}`, {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  getAllProduct: async () => {
    try {
      const response = await fetch(URL + `product/product-list`, {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },

  deleteProductById: async (id) => {
    try {
      const response = await fetch(URL + `product/delete/${id}`, {
        method: "delete",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  updateProduct: async (data, id) => {
    // console.log(data.get("categoryName"))
    try {
      const response = await fetch(`${URL}product/update/${id}`, {
        method: "put",
        body: data,
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
};
