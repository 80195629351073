import React from 'react'

const DealerList = () => {
  return (
    <div>
      Dealer list
    </div>
  )
}

export default DealerList
