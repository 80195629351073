import React from 'react'

const ArchitectList = () => {
  return (
    <div>
      Architect list
    </div>
  )
}

export default ArchitectList
