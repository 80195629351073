import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import ListInTable from "../../Reusable Components/DataTable";
import "../../App.sass";
import "../AdminPages/style.css";
import { PRODUCT_API } from "../../API/addProductAPI";
import baseUrl from "../../API/apiUrl";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DESIGN_API } from "../../API/designAPI";
import { Button } from "@mui/material";
import { ProfileContext } from "../../App";

const MyProduct = () => {
  const { user } = useContext(ProfileContext);
  var arrayData = [];
  const userColumns = [
    {
      field: "id",
      headerName: "Id",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.id}</div>;
      },
    },
    {
      field: "name",
      headerName: "Product Name",
      headerClassName: "custom_header",
      width: 200,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.name}</div>;
      },
    },
    {
      field: "uploadingUser",
      headerName: "Uploading User",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.uploadingUser}</div>;
      },
    },
    {
      field: "price",
      headerName: "Price",
      headerClassName: "custom_header",
      width: 100,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.price}</div>;
      },
    },
    {
      field: "descriptionOne",
      headerName: "Description One",
      headerClassName: "custom_header",
      width: 200,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.descriptionOne}</div>;
      },
    },
    {
      field: "descriptionTwo",
      headerName: "Description Two",
      headerClassName: "custom_header",
      width: 200,
      renderCell: (params) => {
        return <div className="cell_img_div">{params.row.descriptionTwo}</div>;
      },
    },
    {
      field: "descriptionThree",
      headerName: "Description Three",
      headerClassName: "custom_header",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cell_img_div">{params.row.descriptionThree}</div>
        );
      },
    },
    // {
    //   field: "serviceLocationState",
    //   headerName: "Location State",
    //   headerClassName: "custom_header",
    //   width: 150,
    //   renderCell: (params) => {
    //     return (
    //       <div className="cell_img_div">{params.row.serviceLocationState}</div>
    //     );
    //   },
    // },
    // {
    //   field: "serviceLocationCity",
    //   headerName: "Location City",
    //   headerClassName: "custom_header",
    //   width: 150,
    //   renderCell: (params) => {
    //     return (
    //       <div className="cell_img_div">{params.row.serviceLocationCity}</div>
    //     );
    //   },
    // },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "custom_header",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            className={`cell_status_div px-2 py-1 ${
              params.row.status === "Approved"
                ? "Approved"
                : params.row.status === "Pending"
                ? "Pending"
                : "Rejected"
            }`}
          >
            {params.row.status}
          </div>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);

  function handleDelete(id) {
    if (window.confirm("Are you sure want to delete?")) {
      PRODUCT_API.deleteProductById(id).then((resp) => {
        if (resp.status === 200) {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          getAllProductList();
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  }
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      headerClassName: "custom_header",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cell_action_div">
            <Link
              to={`/dealer/product/view/${params.row.id}`}
              style={{ textDecoration: "none", color: "unset" }}
              className="view_btn"
            >
              View
            </Link>
            <div
              className="delete_btn"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    document.title = "MyGriha | Dealer Product";
    getAllProductList();
  }, []);

  const getAllProductList = () => {
    PRODUCT_API.getProductByUser(user?._id).then((resp) => {
      if (resp.status === 200) {
        setRows(resp.data);
      } else {
        setRows([]);
      }
    });
  };

  rows?.map((item) => {
    return arrayData?.push({
      id: item._id,
      name: item.name,
      category: item.category,
      price: item.price,
      descriptionOne: item.descriptionOne,
      descriptionTwo: item.descriptionTwo,
      descriptionThree: item.descriptionThree,
      // serviceLocationState:item.serviceLocationState,
      // serviceLocationCity:item.serviceLocationCity,
      status: item.status,
      uploadingUser:item.uploadingUserRole.substring(5)
    });
  });

  return (
    <>
      <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <UserTable className="users_list_container">
            <div className="users_list_container_title">
              <h4 className="p-2 mb-0">All Products</h4>
              <Link to="/dealer/product-upload" className="me-2">
                <Button variant="contained" size="small" color="success">
                  Add Product
                </Button>
              </Link>
            </div>
            <div>
              <ListInTable
                rows={arrayData}
                columns={userColumns.concat(actionColumn)}
              />
            </div>
          </UserTable>
        </div>
      </main>
    </>
  );
};

export const UserTable = styled.div`
  z-index: 0;
  /* Resetting MUI table color props */
  p,
  div.MuiTablePagination-actions > button {
    color: inherit;
  }
  /* END */
`;

export default MyProduct;
