import React, { useContext, useState } from "react";
import "../../Css/construction.css";
import { Link } from "react-router-dom";
import { API } from "../../API/usersAPI";
import { ToastContainer, toast } from "react-toastify";
import { ProfileContext } from "../../App";

const EstimateModal = ({
  showEstimateModal,
  setShowEstimateModal,
  popupPage,
  setPopupPage,
  setProductCheck,
  setSelect,
}) => {
  const { estimateData } = useContext(ProfileContext);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    API.login(loginData).then((response) => {
      if (response.status === 200) {
        sessionStorage.setItem("user-login", true);
        setPopupPage("");
        const sendData = {
          name: response.data.username,
          email: response.data.email,
          phone: response.data.phone,
          city: estimateData.city,
          building_type: estimateData.building_type,
          plotarea: estimateData.plotArea,
          slabarea: estimateData.slabArea,
          floor: estimateData.floors,
          cement: estimateData.cement,
          brick: estimateData.brick,
          steel: estimateData.steel,
          paint: estimateData.paint
        };
        // MATERIAL_API.getMaterialRate(sendData).then((resp)=>{
        //   console.log(resp);
        //   setCost(resp.Rate);
        // })
        API.estimateQuery(sendData).then((response) => {
          if (response.status === 201) {
            toast.success(response.message);
            setShowEstimateModal(!showEstimateModal);
          }
        });
        setLoginData({
          email: "",
          password: ""
        })
      }
    })
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        autoClose={3000}
        theme="light"
      />
      {popupPage === "login" && (
        <div className="container-fluid ">
          <div className="login-container">
            <form className="login-form" onSubmit={handleLogin}>
              <button
                type="button"
                className="fw-bold cancel-btn"
                onClick={() => {
                  setShowEstimateModal(!showEstimateModal);
                  setPopupPage("");
                  setProductCheck([]);
                  setSelect("cement");
                }}
              >
                X
              </button>
              <h1 className="text-center">Welcome Back</h1>
              <p className="text-center">Please login to your account</p>
              <div className="input-group">
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={loginData.email}
                />
              </div>
              <div className="input-group">
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={loginData.password}
                />
              </div>
              <button type="submit" className="button-css">
                Login
              </button>
              <div className="bottom-text">
                <p>
                  Don't have an account?{" "}
                  <Link to="/signup">Sign Up</Link>
                </p>
                <p>
                  <Link to="/forgot-p">Forgot Password?</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default EstimateModal;
