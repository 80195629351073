import React, { useEffect, useContext, useState } from "react";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import "../../App.sass";
import "../Home/Home.sass";
import "../AdminPages/style.css";
import { ProfileContext } from "../../App";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API } from "../../API/usersAPI";
import baseUrl from "../../API/apiUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { State, City } from "country-state-city";
import { DESIGN_API } from "../../API/designAPI";

const UploadDesign = () => {
    const {user} = useContext(ProfileContext);
    const navigate = useNavigate();
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [designData, setDesignData] = useState({
    title: "",
    plotLength: "",
    width: "",
    bedroom: "",
    toilet: "",
    floor: "",
    buildingType: "",
    description: "",
    twoDImage: "",
    threeDImage: "",
    cadImage:"",
    serviceLocationState: "",
    serviceLocationCity: "",
  });
  useEffect(() => {
    document.title = "MyGriha | Upload Designs";
    setStateList(State.getStatesOfCountry("IN"));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      setDesignData({ ...designData, [name]: e.target.files[0] });
    } else {
      if (name === "serviceLocationState") {
        setDesignData({
          ...designData,
          [name]: value,
        });
        setCityList(City.getCitiesOfState("IN", value));
      } else {
        setDesignData({ ...designData, [name]: value });
      }
    }
  };

  const designSubmit = (e) => {
    e.preventDefault();
    if (!designData.serviceLocationState) {
      toast.error("Service location state required..", {
        position: "top-right",
      });
    } else if (!designData.serviceLocationCity) {
      toast.error("Service location city required..", {
        position: "top-right",
      });
    } else if (!designData.title) {
      toast.error("Design title required..", {
        position: "top-right",
      });
    } else if (!designData.plotLength) {
      toast.error("Plot Length required..", {
        position: "top-right",
      });
    } else if (!designData.width) {
      toast.error("Plot width required..", {
        position: "top-right",
      });
    } else if (!designData.buildingType) {
      toast.error("Building type required..");
    }
    else if (!designData.twoDImage) {
      toast.error("2D Floor Plan required..", {
        position: "top-right",
      });
    } else if (!designData.cadImage) {
        toast.error("CAD File required..", {
          position: "top-right",
        });
      }
      else if (!designData.cadPrice) {
        toast.error("CAD Price required..", {
          position: "top-right",
        });
      }
    else {
      const formData = new FormData();
      formData.append(
        "serviceLocationState",
        State.getStateByCodeAndCountry(designData.serviceLocationState, "IN")
          .name
      );
      formData.append("serviceLocationCity", designData.serviceLocationCity);
      formData.append("title", designData.title);
      formData.append("plotLength", designData.plotLength);
      formData.append("width", designData.width);
      formData.append("bedroom", designData.bedroom);
      formData.append("toilet", designData.toilet);
      formData.append("floor", designData.floor);
      formData.append("buildingType", designData.buildingType);
      formData.append("description", designData.description);
      formData.append("twoDImage", designData.twoDImage);
      formData.append("threeDImage", designData.threeDImage);
      formData.append("cadImage", designData.cadImage);
      formData.append("cadPrice", designData.cadPrice);
      formData.append("uploadingUser", user?._id);
      formData.append("status", "Pending");
      formData.append("uploadingUserRole", "ROLE_ARCHITECT");
      DESIGN_API.addDesign(formData).then((resp) => {
        if (resp.status === 201) {
          toast.success(resp.message, {
            position: "top-right",
          });
          setDesignData({
            title: "",
            plotLength: "",
            width: "",
            bedroom: "",
            toilet: "",
            floor: "",
            buildingType: "",
            description: "",
            twoDImage: "",
            threeDImage: "",
            cadImage: "",
            cadPrice: "",
            serviceLocationState: "",
            serviceLocationCity: "",
          })
          navigate("/architect")
        } else {
          toast.error(resp.message, {
            position: "top-right",
          });
        }
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel" id="report_page">
          <Navbar />
          <div className="cards_container">
            <div className="profile_title">
              <h5 className="p-2 mb-0">Upload Design</h5>
            </div>
          </div>
          <div className="cards_container">
            <div className="profile_container">
              <form onSubmit={designSubmit}>
                <div className="row m-5">
                  <div className="col-md-6 ">
                    <label htmlFor="profile">Service Location State<span style={{ color: "red" }}>*</span></label>
                    <select
                      className="form-select "
                      name="serviceLocationState"
                      value={designData.serviceLocationState}
                      onChange={handleChange}
                    >
                      <option>--Select State--</option>
                      {stateList?.length > 0 &&
                        stateList?.map((state,index) => {
                          return (
                            <option key={index} value={state.isoCode}>{state.name}</option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">Service Location City<span style={{ color: "red" }}>*</span></label>
                    <select
                      className="form-select "
                      name="serviceLocationCity"
                      value={designData.serviceLocationCity}
                      onChange={handleChange}
                    >
                      <option>--Select City--</option>
                      {cityList?.length > 0 &&
                        cityList?.map((city,index) => {
                          return <option key={index} value={city.name}>{city.name}</option>;
                        })}
                    </select>
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6 ">
                    <label htmlFor="profile">Title<span style={{ color: "red" }}>*</span></label>
                    <input
                      type="text"
                      id="profile"
                      name="title"
                      value={designData.title}
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">Plot Length<span style={{ color: "red" }}>*</span></label>
                    <input
                      type="number"
                      id="profile"
                      value={designData.plotLength}
                      name="plotLength"
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">Plot Width<span style={{ color: "red" }}>*</span></label>
                    <input
                      type="number"
                      id="profile"
                      value={designData.width}
                      name="width"
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">Number of Bedrooms</label>
                    <input
                      type="number"
                      id="profile"
                      value={designData.bedroom}
                      name="bedroom"
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">Number of Toilets</label>
                    <input
                      type="number"
                      id="profile"
                      value={designData.toilet}
                      name="toilet"
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">Number of Floors</label>
                    <input
                      type="number"
                      id="profile"
                      value={designData.floor}
                      name="floor"
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6 ">
                    <label htmlFor="profile">Building Type<span style={{ color: "red" }}>*</span></label>
                    <select
                      className="form-select "
                      name="buildingType"
                      value={designData.buildingType}
                      onChange={handleChange}
                    >
                      <option>--Select--</option>
                      <option value="Residential">Residential</option>
                      <option value="Commercial">Commercial</option>
                      <option value="Industrial">Industrial</option>
                      <option value="Hospital">Hospital</option>
                      <option value="Mixed Use">Mixed Use</option>
                    </select>
                  </div>
                  <div className="col-md-6 ">
                    <label htmlFor="profile">Design Description</label>
                    <input
                      type="text"
                      id="profile"
                      value={designData.description}
                      name="description"
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">2D Floor Plan<span style={{ color: "red" }}>*</span></label>
                    <input
                      type="file"
                      id="profile"
                      name="twoDImage"
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">3D View</label>
                    <input
                      type="file"
                      id="profile"
                      name="threeDImage"
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">CAD File<span style={{ color: "red" }}>*</span></label>
                    <input
                      type="file"
                      id="profile"
                      name="cadImage"
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">CAD Price<span style={{ color: "red" }}>*</span></label>
                    <input
                      type="number"
                      id="profile"
                      name="cadPrice"
                      value={designData.cadPrice}
                      onChange={handleChange}
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-success float-end m-5">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UploadDesign;
