import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../Reusable Styling/AddItem.sass";
import baseUrl from "../../API/apiUrl";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import { PRODUCT_API } from "../../API/addProductAPI";
import { Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

const ProductView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [productData, setProductData] = useState({});
  const [uploadRole, setUploadRole] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");

  useEffect(() => {
    PRODUCT_API.getProductById(params.id).then((resp) => {
      if (resp.status === 200) {
        // console.log(resp.data)
        setApprovalStatus(resp.data[0].status);
        setUploadRole(resp.data[0].uploadingUserRole);
        setProductData(resp.data[0]);
      } else {
        setProductData({});
      }
    });
  }, [params.id]);
  return (
    <>
      <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <div className="add_item_title_div mt-4">
            <h6>View Product</h6>
          </div>
          <div className="cards_container">
            <div className="profile_container">
              <form>
                <div className="row m-5">
                  <div className="col-md-6 ">
                    <label htmlFor="profile">
                      Service Location State
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select "
                      name="serviceLocationState"
                      value={productData.serviceLocationState}
                      disabled
                    >
                      <option>{productData.serviceLocationState}</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Service Location City
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select "
                      name="serviceLocationCity"
                      value={productData.serviceLocationCity}
                      disabled
                    >
                      <option>{productData.serviceLocationCity}</option>
                    </select>
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Product Category
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select "
                      name="category"
                      value={productData.category}
                      disabled
                    >
                      <option value="">{productData.category}</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Photo<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    {productData.photo ? (
                      <img
                        src={`${baseUrl}/${productData.photo}`}
                        alt="/"
                        className="w-25 mt-3"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="profile"
                      value={productData.name}
                      name="name"
                      disabled
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Quantity<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      id="profile"
                      name="quantity"
                      value={productData.quantity}
                      disabled
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Unit<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="profile"
                      name="unit"
                      value={productData.unit}
                      disabled
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Price
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      id="profile"
                      name="price"
                      value={productData.price}
                      disabled
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">
                      Description One<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="profile"
                      name="descriptionOne"
                      value={productData.descriptionOne}
                      disabled
                      className="profile_input_upload"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="profile">Description Two</label>
                    <input
                      type="text"
                      id="profile"
                      name="descriptionTwo"
                      value={productData.descriptionTwo}
                      disabled
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-md-6">
                    <label htmlFor="profile">Description Three</label>
                    <input
                      type="text"
                      id="profile"
                      name="descriptionThree"
                      value={productData.descriptionThree}
                      disabled
                      className="profile_input_upload"
                    />
                  </div>
                </div>
                <Link to={`/dealer/product/edit/${params.id}`}>
                  <button
                    type="submit"
                    className="btn btn-primary float-end m-5"
                  >
                    Edit
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ProductView;
