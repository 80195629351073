import React, { useContext } from "react";
import Hero from "../component/HomeComponents/Hero";
import Navbar from "../common/Navbar";
import Sidebar from "../component/HomeComponents/Sidebar";
import Calculator from "../component/HomeComponents/Calculator";
import Modal from "../component/HomeComponents/Modal";
import Quick from "../component/HomeComponents/Quick";
import House from "../component/HomeComponents/House";
import Talk from "../component/HomeComponents/Talk";
import Footer from "../component/HomeComponents/Footer";
import Menu from "../component/HomeComponents/Menu";
import Emical from "../component/HomeComponents/Emical";
// import Emical from '../componenth/Emical';


const Home = () => {
  return (
    <>
      <Navbar/>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 g-0">
              <Sidebar/>
            </div>
            <div className="col-lg-8 hero-box">
              <Modal />
              <Hero />
            </div>
            <div className="col-lg-2">
              <Calculator />
              <br />
              {/* <Emical /> */}
            </div>
          </div>
          <div className="row">
            <Quick />
            <House />
            <Talk />
            <Footer />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
