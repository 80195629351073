import React, { useState, useEffect, useContext } from "react";
import { Link,useNavigate } from "react-router-dom";
import { ThemeContext, ProfileContext } from "../../App";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import "./Navbar.sass";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import imageIcon from "../../icon/user-icon.png"

const Navbar = () => {
  const { user,setUser } = useContext(ProfileContext);
  const [menu, setMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [profile, setProfile] = useState(false);
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();

  // Handle hamburger menu display for med and small devices
  function handleMenu() {
    setMenu(!menu);
    document
      .querySelector(".dashboard_container_left_panel")
      .setAttribute("style", `${menu ? "" : "display: block"}`);
  }

  // Handle user profile menu
  const adminProfileDiv = document.querySelector(".admin_profile_div");
  function handleProfileMenu() {
    const isProfileMenuOpen = profileMenu;
    setProfileMenu(!isProfileMenuOpen);
    document
      .querySelector(".items_div")
      .setAttribute(
        "style",
        `${isProfileMenuOpen ? "" : "display: block; opacity: 1"}`
      );
    if (isProfileMenuOpen && profile) {
      setProfile(false);
      adminProfileDiv.setAttribute("style", "display: none");
    }
  }

  // Handle welcome popup
  useEffect(() => {
    function handlePopup() {
      setPopup(true);
      const popupDiv = document.querySelector(".welcome_popup_div");
      if (popupDiv) {
        popupDiv.style.display = "block";
      }
      localStorage.setItem("popupShown", true);
    }

    const popupShown = localStorage.getItem("popupShown");
    if (!popupShown) {
      handlePopup();
    }
  }, []);

  const LogOut = ()=>{
    localStorage.removeItem("user");
    setUser("");
    navigate("/");
  }
  // --------------------------------------------------------------------------
  return (
    <>
      <div className="navbar_container">
        <div className="navbar_logo_div">
          {menu ? (
            <CloseIcon
              className="hamburger_menu_btn bg-danger text-light p-1"
              onClick={handleMenu}
            />
          ) : (
            <MenuIcon className="hamburger_menu_btn" onClick={handleMenu} />
          )}
          <Link to="/home" style={{ textDecoration: "none", color: "voilet" }}>
            <span className="logo" style={{ fontSize: "18px" }}>
              MyGriha{" "}
            </span>
            {user.role && <span>{`(${user.role.substring(5).toUpperCase()})`}</span>}
          </Link>
        </div>
        <div className="search_and_settings_div">
          {/* <div
            className="search_div"
            style={{
              border: "1px solid rgb(90, 88, 88)",
              padding: "2px",
              borderRadius: "4px",
            }}
          >
            <input
              type="text"
              placeholder="Search..."
              className="search_input"
            />
            <SearchIcon />
          </div> */}

          <div className="items_div_wrapper">
            <div className="items_div">
              <Link to={`/${user?.role.substring(5).toLowerCase()}/profile`} style={{ textDecoration: "none", color: "white" }}>
                <div className="item">
                  <AccountCircleOutlinedIcon className="icon" />
                  <p className="mb-0 mx-1">Profile</p>
                </div>
              </Link>
              {/* <Link to="/" style={{ textDecoration: "none", color: "white" }}> */}
                <div className="item" onClick={LogOut}>
                  <ExitToAppIcon className="icon" />
                  <p className="mb-0 mx-1">Log Out</p>
                </div>
              {/* </Link> */}
            </div>
            <div
              className="user_profile_div d-flex align-items-center justify-content-center"
              onClick={handleProfileMenu}
            >
              <p className="username mb-0">{user.username}</p>
              <img src={imageIcon} alt="Dashboard user" className="avatar" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
