import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../Reusable Styling/AddItem.sass";
import baseUrl from "../../API/apiUrl";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import { DESIGN_API } from "../../API/designAPI";
import { State, City } from "country-state-city";
import { Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewDesign = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [designData, setDesignData] = useState({});
  const [uploadRole, setUploadRole] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  useEffect(() => {
    DESIGN_API.getDesignById(params.id).then((resp) => {
      if (resp.status === 200) {
        // console.log(resp.data)
        setApprovalStatus(resp.data[0].status);
        setUploadRole(resp.data[0].uploadingUserRole);
        setDesignData(resp.data[0]);
      } else {
        setDesignData({});
      }
    });
  }, [params.id]);

  const handleStatus = (data) => {
    DESIGN_API.updateDesignStatus(data, params?.id).then((resp) => {
      if (resp.status === 200) {
        toast.success(resp.message, {
          position: "top-right",
        });
        navigate("/admin/design");
      } else {
        toast.error(resp.message, {
          position: "top-right",
        });
      }
    });
  };

  const handleReject = () => {
    const data = {
      status: "Rejected",
    };
    handleStatus(data);
  };
  const handleApproved = () => {
    const data = {
      status: "Approved",
    };
    handleStatus(data);
  };
  return (
    <>
      <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel">
          <Navbar />
          <div className="add_item_title_div mt-4">
            <h6>View Design</h6>
          </div>
          <div className="add_item_title_div mt-2 text-center text-success">
            <span className="mt-2">Admin Can Update Only Own Design...</span>
          </div>
          <div className="row ">
            <div className="container">
              <div className="row product-box px-5 pe-5">
                <div className="col-lg-12 p-4">
                  <form>
                    <div className="row">
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Service Location State
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="p-1 form-select rounded-0"
                            name="serviceLocationState"
                            value={designData.serviceLocationState}
                            disabled
                          >
                            <option value="">
                              {designData.serviceLocationState}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Service Location City
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="p-1 form-select rounded-0"
                            name="serviceLocationCity"
                            value={designData.serviceLocationCity}
                            disabled
                          >
                            <option value="">
                              {designData.serviceLocationCity}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Title <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="title"
                            value={designData.title}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Plot Length <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="plotLength"
                            value={designData.plotLength}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Plot Width <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="width"
                            value={designData.width}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Number of bedrooms
                          </label>
                          <input
                            type="number"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="bedroom"
                            value={designData.bedroom}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Number of Toilet
                          </label>
                          <input
                            type="number"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="toilet"
                            value={designData.toilet}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Number of Floor
                          </label>
                          <input
                            type="number"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="floor"
                            value={designData.floor}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Building Type{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="p-1 form-select rounded-0"
                            name="buildingType"
                            value={designData.buildingType}
                            disabled
                          >
                            <option value="">{designData.buildingType}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            2D Floor Plan{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          {designData.twoDImage ? (
                            <img
                              src={`${baseUrl}/${designData.twoDImage}`}
                              alt="/"
                              className="w-25 mt-3"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            3D Floor Plan{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          {designData.threeDImage ? (
                            <img
                              src={`${baseUrl}/${designData.threeDImage}`}
                              alt="/"
                              className="w-25 mt-3"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            CAD File
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          {designData.cadImage ? (
                            <img
                              src={`${baseUrl}/${designData.cadImage}`}
                              alt="/"
                              className="w-25 mt-3"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            CAD Price
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="p-1"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="cadPrice"
                            value={designData.cadPrice}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Design Description
                          </label>
                          <textarea
                            name="description"
                            value={designData.description}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {uploadRole?.substring(5).toLowerCase() === "architect" ? (
                      <div className="float-end">
                        {approvalStatus?.toLowerCase() === "approved" ? (
                          <Button
                            size="small"
                            variant="contained"
                            color="success"
                          >
                            Approved
                          </Button>
                        ) : (
                          <>
                            <Button
                              size="small"
                              variant="contained"
                              color="warning"
                              onClick={handleReject}
                            >
                              Reject
                            </Button>
                            &nbsp;
                            <Button
                              size="small"
                              variant="contained"
                              color="success"
                              onClick={handleApproved}
                            >
                              Approved
                            </Button>
                          </>
                        )}
                      </div>
                    ) : (
                      <Link to={`/admin/design/edit/${params.id}`}>
                        <button
                          type="submit"
                          className="btn btn-primary float-end mt-2"
                        >
                          Edit
                        </button>
                      </Link>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ViewDesign;
