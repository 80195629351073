import { FaCentercode } from "react-icons/fa";
import { FaDeezer } from "react-icons/fa";

const Modal = () => {

  return (
    <>
      <div className="modal-btnr">
        <button
          type="button"
          className="btn-modal"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          How It Works
        </button>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                How It Works
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <FaCentercode className="modal-icon" />
                  <h2>Video</h2>
                </div>
                <div className="col-lg-6">
                  <FaDeezer className="modal-icon" />
                  <h2>FlowChart</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
