import React from "react";
import { Link } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import "./Card.sass";

const Card = ({ type,userCount,architectCount,dealerCount}) => {
  let data;
  switch (type) {
    case "users":
      data = {
        title: "Users",
        link: "/admin/users",
        linkName: "See all users",
        count:userCount
      };
      break;

    case "architects":
      data = {
        title: "Architects",
        link: "/admin/architect-list",
        linkName: "See all orders",
        count:architectCount
      };
      break;

    case "dealers":
      data = {
        title: "Dealers",
        link: "/admin/dealer-list",
        linkName: "See all dealers",
        count:dealerCount
      };
      break;

    case "balance":
      data = {
        title: "Balance",
        link: "/admin/balance",
        linkName: "See details",
        count:`INR ${0}`
      };
      break;

    default:
      break;
  }

  return (
    <>
      <div
        className="card_div"
        style={{
          padding: "0.5rem",
        }}
      >
        <div className="card_div_left_content align-items-start">
          <p className="title1">{data.title}</p>
          <p className="text-dark">{data.count}</p>
          <Link
            to={data.link}
            style={{ textDecoration: "none" }}
          >
            <p className="link">{data.linkName}</p>
          </Link>
        </div>
        
      </div>
    </>
  );
};

export default Card;
