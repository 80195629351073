import React from "react";
import "../../../Css/consult.css";

const Consult = ({ closeConsult }) => {
  return (
    <>
      <>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header text-light" style={{backgroundColor:"#CC3333"}}>
                <h5 className="modal-title text-center">
                  Request Consultation
                </h5>
                <button
                  type="button"
                  className="btn-close text-light"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div
                  className="form"
                  style={{
                    background: "#fff",
                    // boxShadow: "1px 0px 20px 2px #bebbbb8a",
                    padding: "10px 30px",
                  }}
                >
                  <form
                    id="slider-form1"
                    role="form"
                    action="thankyou"
                    method="post"
                    encType="multipart/from-data"
                  >
                    <input type="hidden" name="utm_source" defaultValue="" />
                    <input type="hidden" name="utm_campaign" defaultValue="" />
                    <input type="hidden" name="utm_medium" defaultValue="" />
                    <input
                      type="hidden"
                      name="utm_campaignid"
                      defaultValue=""
                    />
                    <input type="hidden" name="utm_adgroupid" defaultValue="" />
                    <input type="hidden" name="utm_matchtype" defaultValue="" />
                    <input type="hidden" name="utm_device" defaultValue="" />
                    <input
                      type="hidden"
                      name="utm_devicemodel"
                      defaultValue=""
                    />
                    <input type="hidden" name="utm_keyword" defaultValue="" />
                    <input
                      type="text"
                      className="form-control form-group"
                      name="qte-name"
                      id="name511"
                      placeholder="Full Name"
                      required=""
                    />
                    <input
                      type="email"
                      className="form-control form-group"
                      name="q-email"
                      id="email511"
                      placeholder="Email ID"
                      required=""
                    />
                    <input
                      type="number"
                      className="form-control form-group"
                      name="telephone"
                      id="number511"
                      placeholder="Mobile Number"
                      required=""
                    />
                    <select
                      className="form-control form-group"
                      name="city"
                      id="city511"
                      placeholder="City"
                      required=""
                    >
                      <option value="">Choose City</option>
                      <option value="Patna">Patna</option>
                      <option value="Noida">Noida </option>
                      <option value="Gurgaon">Gurgaon</option>
                      <option value="Ranchi">Ranchi</option>
                    </select>
                    <textarea
                      className="form-control form-group"
                      name="message"
                      id="message1"
                      placeholder="By when do you want to start home construction?"
                      rows={4}
                      
                    />
                    <div className="checkboxseting form-group">
                      <input
                        type="checkbox"
                        
                      />
                      I agree to the{" "}
                      <a href="#" target="_blank">
                        terms &amp; conditions
                      </a>{" "}
                      and{" "}
                      <a
                        href="#"
                        target="_blank"
                      >
                        privacy policy
                      </a>
                    </div>
                    <button
                      type="submit"
                      style={{ background: "#303030", borderColor: "#303030" }}
                      className="btn btn-primary btn-black mx-auto d-block"
                    >
                      Submit{" "}
                    </button>
                    <div id="quote-result" className="mt-xs" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Consult;
