import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AdminHome from "./Pages/Home/Home";
import Login from "./common/Login";
import Logout from "./Pages/Logout/Logout";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import "./App.sass";
import "./App.css";
import "./index.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import Home from "./HeaderComponent/Home";
import Estimate from "./HeaderComponent/Estimate/Estimate";
import CategoriesList from "./Pages/AdminPages/CategoriesList";
import AddCategories from "./Pages/AdminPages/AddCategories";
import ProductList from "./Pages/AdminPages/ProductList";
import AddProduct from "./Pages/AdminPages/AddProduct";
import SignUp from "./common/SignUp";
import ShowPackages from "./HeaderComponent/Architecture/ShowPackages";
import Professionals from "./HeaderComponent/Architecture/Professionals";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./Pages/AdminPages/Profile";
import ViewCategory from "./Pages/AdminPages/ViewCategory";
import UpdateCategory from "./Pages/AdminPages/UpdateCategory";
import ViewProduct from "./Pages/AdminPages/ViewProduct";
import UpdateProduct from "./Pages/AdminPages/UpdateProduct";
import DesignList from "./Pages/AdminPages/DesignList";
import AddDesign from "./Pages/AdminPages/AddDesign";
import ViewDesign from "./Pages/AdminPages/ViewDesign";
import UpdateDesign from "./Pages/AdminPages/UpdateDesign";
import UserList from "./Pages/AdminPages/UserList";
import Index from "./Pages/ArchitectManagement";
import ArchitectApplication from "./Pages/ArchitectManagement/ArchitectApplication";
import UploadDesign from "./Pages/ArchitectManagement/UploadDesign";
import MyDesigns from "./Pages/ArchitectManagement/MyDesigns";
import MyOrders from "./Pages/ArchitectManagement/MyOrders";
import ArchitectDesignView from "./Pages/ArchitectManagement/ViewDesign";
import ArchitectUpdateView from "./Pages/ArchitectManagement/UpdateDesign";
import MyNotifications from "./Pages/ArchitectManagement/MyNotifications";
import DealerHome from "./Pages/DealerManagement/Home";
import Application from "./Pages/DealerManagement/Application";
import UploadDealerProduct from "./Pages/DealerManagement/UploadProduct";
import MyProduct from "./Pages/DealerManagement/MyProduct";
import ProductView from "./Pages/DealerManagement/ProductView";
import ProductUpdate from "./Pages/DealerManagement/ProductUpdate";
import DealerOrders from "./Pages/DealerManagement/MyOrders";
import DealerNotifications from "./Pages/DealerManagement/MyNotifications";
import UserHome from "./Pages/UserManagement/Home";
import ForgotPassword from "./common/ForgotPassword";
import DealerList from "./Pages/AdminPages/DealerList";
import ArchitectList from "./Pages/AdminPages/ArchitectList";
import ProfileEdit from "./common/ProfileEdit";
import ProfileView from "./common/ProfileView";

// Context for the theme settings and the functions to handle them
export const LoaderContext = React.createContext();
export const ThemeContext = React.createContext();
export const ProfileContext = React.createContext();

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [popupPage, setPopupPage] = useState("");
  const [cost, setCost] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const appRef = useRef(null);
  const [userName, setUserName] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [message, setMessage] = useState("");
  const [user, setUser] = useState(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      return JSON.parse(loggedInUser);
    } else {
      return null;
    }
  });
  const [userRole, setUserRole] = useState("");
  const [estimatePopup, setEstimatePopup] = useState(false);
  const [estimateData, setEstimateData] = useState({});
  const [select, setSelect] = useState("cement");
  const [productCheck, setProductCheck] = useState([]);
  useEffect(() => {
    // Retrieve the values from local storage and set them to component state
    const storedUserName = localStorage.getItem("userName");
    setUserName(storedUserName ? storedUserName : "");
    setIsLoading(false);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (message) {
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [message]);

  // Context values to pass to the children components
  const LoaderContextValues = {
    isLoading,
    setIsLoading,
  };

  const ProfileContextValues = {
    userName,
    setUserName,
    profilePic,
    setProfilePic,
    message,
    setMessage,
    user,
    setUser,
    userRole,
    setUserRole,
    estimatePopup,
    setEstimatePopup,
    estimateData,
    setEstimateData,
    select,
    setSelect,
    productCheck,
    setProductCheck,
  };

  return (
    <>
      <ToastContainer />
      <div className="app" ref={appRef}>
        <LoaderContext.Provider value={LoaderContextValues}>
          <ProfileContext.Provider value={ProfileContextValues}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot-p" element={<ForgotPassword />} />
                <Route
                  path="/estimate"
                  element={
                    <Estimate
                      popupPage={popupPage}
                      setPopupPage={setPopupPage}
                      cost={cost}
                      setCost={setCost}
                    />
                  }
                />
                <Route path="/architect/packages" element={<ShowPackages />} />
                <Route
                  path="/architect/professional"
                  element={<Professionals />}
                />

                {/* Admin routes */}
                <Route
                  path="/admin/dashboard"
                  element={user ? <AdminHome /> : <Login />}
                />
                <Route
                  path="/admin/profile"
                  element={user ? <Profile /> : <Login />}
                />
                 <Route
                  path="/profile/edit/:id"
                  element={user ? <ProfileEdit /> : <Login />}
                />
                <Route
                  path="/profile/view/:id"
                  element={user ? <ProfileView /> : <Login />}
                />
                <Route
                  path="/admin/category"
                  element={user ? <CategoriesList /> : <Login />}
                />
                <Route
                  path="/admin/category/view/:id"
                  element={user ? <ViewCategory /> : <Login />}
                />
                <Route
                  path="/admin/category/edit/:id"
                  element={user ? <UpdateCategory /> : <Login />}
                />
                <Route
                  path="/admin/product/view/:id"
                  element={user ? <ViewProduct /> : <Login />}
                />
                <Route
                  path="/admin/product/edit/:id"
                  element={user ? <UpdateProduct /> : <Login />}
                />
                <Route
                  path="/admin/product"
                  element={user ? <ProductList /> : <Login />}
                />
                <Route
                  path="admin/add-category"
                  element={user ? <AddCategories /> : <Login />}
                />
                <Route
                  path="admin/add-product"
                  element={user ? <AddProduct /> : <Login />}
                />
                <Route
                  path="/admin/design"
                  element={user ? <DesignList /> : <Login />}
                />
                <Route
                  path="/admin/add-design"
                  element={user ? <AddDesign /> : <Login />}
                />
                <Route
                  path="/admin/design/view/:id"
                  element={user ? <ViewDesign /> : <Login />}
                />
                <Route
                  path="/admin/design/edit/:id"
                  element={user ? <UpdateDesign /> : <Login />}
                />
                <Route
                  path="/admin/users"
                  element={user ? <UserList /> : <Login />}
                />
                <Route
                  path="/admin/dealer-list"
                  element={user ? <DealerList /> : <Login />}
                />
                <Route
                  path="/admin/architect-list"
                  element={user ? <ArchitectList /> : <Login />}
                />
                <Route path="/logout" element={<Logout />} />
                <Route path="*" element={<PageNotFound />} />

                {/* Architect routes */}
                <Route
                  path="/architect/profile"
                  element={user ? <Profile /> : <Login />}
                />
                <Route
                  path="/architect/application"
                  element={user ? <ArchitectApplication /> : <Login />}
                />
                <Route
                  path="/architect/edit-application/:id"
                  element={user ? <ArchitectApplication /> : <Login />}
                />
                <Route
                  path="/architect/edit-profile/:id"
                  element={user ? <Index /> : <Login />}
                />
                <Route
                  path="/architect/designs-upload"
                  element={user ? <UploadDesign /> : <Login />}
                />
                <Route
                  path="/architect/designs"
                  element={user ? <MyDesigns /> : <Login />}
                />
                <Route
                  path="/architect/design/view/:id"
                  element={user ? <ArchitectDesignView /> : <Login />}
                />
                <Route
                  path="/architect/design/edit/:id"
                  element={user ? <ArchitectUpdateView /> : <Login />}
                />
                <Route
                  path="/architect/orders"
                  element={user ? <MyOrders /> : <Login />}
                />
                <Route
                  path="/architect/notifications"
                  element={user ? <MyNotifications /> : <Login />}
                />
                {/* Dealer Routes */}
                <Route
                  path="/dealer/profile"
                  element={user ? <Profile /> : <Login />}
                />
                <Route
                  path="/dealer/edit-profile/:id"
                  element={user ? <DealerHome /> : <Login />}
                />
                <Route
                  path="/dealer/application"
                  element={user ? <Application /> : <Login />}
                />
                <Route
                  path="/dealer/edit-application/:id"
                  element={user ? <Application /> : <Login />}
                />
                <Route
                  path="/dealer/product-upload"
                  element={user ? <UploadDealerProduct /> : <Login />}
                />
                <Route
                  path="/dealer/products"
                  element={user ? <MyProduct /> : <Login />}
                />
                <Route
                  path="/dealer/product/view/:id"
                  element={user ? <ProductView /> : <Login />}
                />
                <Route
                  path="/dealer/product/edit/:id"
                  element={user ? <ProductUpdate /> : <Login />}
                />
                <Route
                  path="/dealer/orders"
                  element={user ? <DealerOrders /> : <Login />}
                />
                <Route
                  path="/dealer/notifications"
                  element={user ? <DealerNotifications /> : <Login />}
                />
                {/* User Routes */}
                <Route
                  path="/user/profile"
                  element={user ? <Profile /> : <Login />}
                />
                <Route
                  path="/user/edit-profile/:id"
                  element={user ? <UserHome /> : <Login />}
                />
              </Routes>
            </BrowserRouter>
          </ProfileContext.Provider>
        </LoaderContext.Provider>
      </div>
    </>
  );
}

export default App;
