import React, { useEffect, useContext, useState } from "react";
import { ThemeContext, LoaderContext } from "../../App";
import { motion } from "framer-motion";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import Card from "../../layout/Card/Card";
import Loader from "../../Reusable Components/Loader";
import "../../App.sass";
import "./Home.sass";
import { ProfileContext } from "../../App";
import { useNavigate } from "react-router-dom";
import TransactionDataTable from "../../layout/Chart&Table/TransactionDataTable";
import {transactionTableData} from "../../layout/Chart&Table/TransactionData";
import { API } from "../../API/usersAPI";

const Home = () => {
  const {user} = useContext(ProfileContext);
  const navigate = useNavigate();
  const { isLoading } = useContext(LoaderContext);
  const [userCount, setUserCount] = useState("");
  const [architectCount, setArchitectCount] = useState("");
  const [dealerCount, setDealerCount] = useState("");

  useEffect(() => {
    document.title = "MyGriha | Admin Dashboard";
    getAllUserList();
  }, []);

  const getAllUserList = () => {
    API.getAllUsers().then((resp) => {
      if (resp.status === 200) {
        setUserCount(resp.data?.length);
        setArchitectCount(resp.data.filter((item)=>item.role === "ROLE_ARCHITECT")?.length);
        setDealerCount(resp.data.filter((item)=>item.role === "ROLE_DEALER")?.length)
      } else {
        setUserCount("");
        setArchitectCount("");
        setDealerCount("");
      }
    });
  };
  return (
    <>
      {" "}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <main className="dashboard_container_main">
              <Sidebar />
              <div className="dashboard_container_right_panel" id="report_page">
                <Navbar/>
                <div className="cards_container">
                  <Card
                    type="users"
                    userCount={userCount}

                  />
                  <Card
                    type="architects"
                    architectCount = {architectCount}
                  />
                  <Card
                    type="dealers"
                    dealerCount={dealerCount}
                  />
                  <Card
                    type="balance"
                  />
                </div>
                {/* <div className="lists_container">
                  <h4 className="p-2">Latest Transaction</h4>
                  <TransactionDataTable tableRows={transactionTableData} />
                </div>  */}
              </div>
            </main>
          </motion.div>
        </>
      )}
    </>
  );
};

export default Home;
