import React, { useContext } from "react";
import "../Css/login.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { API } from "../API/usersAPI";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProfileContext } from "../App";
import { ARCHITECT_MANAGE } from "../API/architectManageAPI";
import { DEALER_MANAGE } from "../API/dealerManageAPI";

const SignUp = () => {
  const navigate = useNavigate();
  const { userRole, setMessage } = useContext(ProfileContext);
  const [registerData, setRegisterData] = useState({
    username: "",
    email: "",
    phone: "",
    password: "",
    role: userRole,
    // role:"ROLE_ADMIN"
  });

  const handleChange = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // API for register user
    API.Signup(registerData).then(async (response) => {
      if (response.status === 201) {
        if (response.data.role.substring(5).toLowerCase() === "architect") {
          const data = { id: response.data._id };
          await ARCHITECT_MANAGE.addPortfolio(data).then((resp) => {
            if (resp.status === 201) {
              setMessage(response.message);
            }
          });
        }
        if (response.data.role.substring(5).toLowerCase() === "dealer") {
          const data = { id: response.data._id };
          await DEALER_MANAGE.addPortfolio(data).then((resp) => {
            if (resp.status === 201) {
              setMessage(response.message);
            }
          });
        }
        navigate("/login");
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  return (
    <>
      <ToastContainer />
      <div className="container-fluid login-sections">
        <div className="login-container">
          <form className="login-form" onSubmit={handleSubmit}>
            <h5>Please Sign in to your account</h5>
            <div className="input-group mt-2">
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Username"
                value={registerData.username}
                onChange={handleChange}
              />
            </div>
            <div className="input-group">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={registerData.email}
                onChange={handleChange}
              />
            </div>
            <div className="input-group">
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Contact number"
                value={registerData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                value={registerData.password}
              />
            </div>
            <button type="submit" className="button-css">
              Sign Up
            </button>
            <div className="bottom-text">
              <p>
                <Link to="/login">Login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignUp;
