import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ProfileContext } from "../../App";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
// import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DownloadIcon from "@mui/icons-material/Download";
import html2PDF from "jspdf-html2canvas";
import "../../App.sass";
import "./Sidebar.sass";
import "./Sidebar.css";
import { BsBell, BsCart, BsChat, BsHeart, BsPersonFillExclamation } from "react-icons/bs";
import { FaMessage } from "react-icons/fa6";
import { FaAddressBook } from "react-icons/fa";

const Sidebar = () => {
  const { user } = useContext(ProfileContext);

  // JsPDF-Html2Canvas function to download the targeted screenshot as pdf
  const downloadPdf = () => {
    const page = document.querySelector(".dashboard_container_right_panel");
    html2PDF(page, {
      jsPDF: {
        format: "a4",
      },
      imageType: "image/jpeg",
      output: "Report.pdf",
    });
  };

  return (
    <>
      <div className="dashboard_container_left_panel">
        <div className="sidebar_menu_items_top_div">
          {user?.role.substring(5).toUpperCase() === "ARCHITECT" ? (
            <ul>
              <h6 className="title text-dark">Main</h6>
              <Link
                to="/architect/profile"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <DashboardIcon className="icon" />
                  <p>Profile</p>
                </li>
              </Link>
              <h6 className="title text-dark">Lists</h6>
              <Link
                to="/architect/application"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <StoreIcon className="icon" />
                  <p>My Application</p>
                </li>
              </Link>
              <Link
                to="/architect/designs"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <StoreIcon className="icon" />
                  <p>My Designs</p>
                </li>
              </Link>
              <Link
                to="/architect/designs-upload"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <CreditCardIcon className="icon" />
                  <p>Upload Designs</p>
                </li>
              </Link>
              <Link
                to="/architect/orders"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <CreditCardIcon className="icon" />
                  <p>Orders</p>
                </li>
              </Link>
              <Link
                to="/architect/notifications"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <CreditCardIcon className="icon" />
                  <p>Notifications</p>
                </li>
              </Link>
            </ul>
          ) : user?.role.substring(5).toUpperCase() === "DEALER" ? (
            <ul>
              <h6 className="title text-dark">Main</h6>
              <Link
                to="/dealer/profile"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <DashboardIcon className="icon" />
                  <p>Profile</p>
                </li>
              </Link>
              <h6 className="title text-dark">Lists</h6>
              <Link
                to="/dealer/application"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <StoreIcon className="icon" />
                  <p>My Application</p>
                </li>
              </Link>
              <Link
                to="/dealer/products"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <StoreIcon className="icon" />
                  <p>My Products</p>
                </li>
              </Link>
              <Link
                to="/dealer/product-upload"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <CreditCardIcon className="icon" />
                  <p>Upload Products</p>
                </li>
              </Link>
              <Link
                to="/dealer/orders"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <CreditCardIcon className="icon" />
                  <p>Orders</p>
                </li>
              </Link>
              <Link
                to="/dealer/notifications"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <CreditCardIcon className="icon" />
                  <p>Notifications</p>
                </li>
              </Link>
            </ul>
          ) : user?.role.substring(5).toUpperCase() === "USER" ? (
            <ul>
              <h6 className="title text-dark">Main</h6>
              <Link
                to="/user/profile"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <BsPersonFillExclamation className="icon" />
                  <p className="mx-2 fw-bold">Profile</p>
                </li>
              </Link>
              <Link
                to="/user/enquiries"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <FaMessage className="icon" />
                  <p className="mx-2 fw-bold">Enquiries</p>
                </li>
              </Link>
              <Link
                to="/user/orders"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <BsCart className="icon " />
                  <p className="mx-2 fw-bold">Orders</p>
                </li>
              </Link>
              <Link
                to="/user/wishlist"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <BsHeart className="icon " />
                  <p className="mx-2 fw-bold">Wishlist</p>
                </li>
              </Link>
              <Link
                to="/user/address"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <FaAddressBook className="icon " />
                  <p className="mx-2 fw-bold">Address</p>
                </li>
              </Link>
              <Link
                to="/user/notifications"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <BsBell className="icon" />
                  <p className="mx-2 fw-bold">Notifications</p>
                </li>
              </Link>
            </ul>
          ) : (
            <ul>
              <h6 className="title text-dark">Main</h6>
              <Link
                to="/admin/dashboard"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <DashboardIcon className="icon" />
                  <p>Dashboard</p>
                </li>
              </Link>
              <Link
                to="/admin/profile"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <DashboardIcon className="icon" />
                  <p>Profile</p>
                </li>
              </Link>

              <h6 className="title text-dark">User Management</h6>
              <Link
                to="/admin/users"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <PersonOutlineIcon className="icon " />
                  <p>Users List</p>
                </li>
              </Link>
              <Link
                to="/admin/dealer-list"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <PersonOutlineIcon className="icon " />
                  <p>Dealers List</p>
                </li>
              </Link>
              <Link
                to="/admin/architect-list"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <PersonOutlineIcon className="icon " />
                  <p>Architects List</p>
                </li>
              </Link>

              <h6 className="title text-dark">Product Management</h6>
              <Link
                to="/admin/category"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <StoreIcon className="icon" />
                  <p>Categories</p>
                </li>
              </Link>
              <Link
                to="/admin/product"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <StoreIcon className="icon" />
                  <p>Products</p>
                </li>
              </Link>
              <Link
                to="/admin/design"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <CreditCardIcon className="icon" />
                  <p>Designs</p>
                </li>
              </Link>
              <h6 className="title text-dark">Payment Management</h6>
              <Link
                to="/users/new"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <PersonAddAltIcon className="icon" />
                  <p>Requests</p>
                </li>
              </Link>
              <Link
                to="/products/new"
                style={{ textDecoration: "none", color: "unset" }}
              >
                <li>
                  <AddShoppingCartIcon className="icon" />
                  <p>Orders</p>
                </li>
              </Link>
              <Link
                to=""
                style={{ textDecoration: "none", color: "unset" }}
                onClick={downloadPdf}
              >
                <li>
                  <DownloadIcon className="icon" />
                  <p>Enquiries</p>
                </li>
              </Link>
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
