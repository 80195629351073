import React, { useEffect, useContext, useState } from "react";
import Sidebar from "../../layout/Sidebar/Sidebar";
import Navbar from "../../layout/Navbar/Navbar";
import "../../App.sass";
import "../Home/Home.sass";
import "../AdminPages/style.css";
import { ProfileContext } from "../../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import baseUrl from "../../API/apiUrl";
import { State, City } from "country-state-city";
import { DEALER_MANAGE } from "../../API/dealerManageAPI";

const Application = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useContext(ProfileContext);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [dealerDetails, setDealerDetails] = useState({
    name: "",
    designation: "",
    companyName: "",
    address: "",
    gstImage: "",
    panImage: "",
    bankDetailsImage: "",
    upiDetails: "",
    aadharImage: "",
    serviceLocationState: "",
    serviceLocationCity: "",
    businessContactNumber: "",
  });

  useEffect(() => {
    document.title = "MyGriha | Dealer Application";
  }, []);
  useEffect(() => {
    DEALER_MANAGE.getPortfolioById(user?._id).then((resp) => {
      if (resp.status === 200) {
        setDealerDetails({
          name: resp.data[0].name ? resp.data[0].name : "",
          designation: resp.data[0].designation ? resp.data[0].designation : "",
          companyName: resp.data[0].companyName ? resp.data[0].companyName : "",
          address: resp.data[0].address ? resp.data[0].address : "",
          gstImage: resp.data[0].gstImage ? resp.data[0].gstImage : "",
          panImage: resp.data[0].panImage ? resp.data[0].panImage : "",
          upiDetails: resp.data[0].upiDetails ? resp.data[0].upiDetails : "",
          bankDetailsImage: resp.data[0].bankDetailsImage
            ? resp.data[0].bankDetailsImage
            : "",
          aadharImage: resp.data[0].aadharImage ? resp.data[0].aadharImage : "",
          serviceLocationState: resp.data[0].serviceLocationState
            ? resp.data[0].serviceLocationState
            : "",
          serviceLocationCity: resp.data[0].serviceLocationCity
            ? resp.data[0].serviceLocationCity
            : "",
          businessContactNumber: resp.data[0].businessContactNumber
            ? resp.data[0].businessContactNumber
            : "",
        });
      }
      if (resp.data[0].serviceLocationState) {
        setCityList(
          City.getCitiesOfState("IN", resp.data[0].serviceLocationState)
        );
      } else {
        setCityList([]);
      }
    });
    setStateList(State.getStatesOfCountry("IN"));
  }, [user?._id, params?.id]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      setDealerDetails({ ...dealerDetails, [name]: e.target.files[0] });
    } else {
      if (name === "serviceLocationState") {
        setDealerDetails({
          ...dealerDetails,
          [name]: value,
        });
        setCityList(City.getCitiesOfState("IN", value));
      } else {
        setDealerDetails({ ...dealerDetails, [name]: value });
      }
    }
  };

  const UpdateDealerPortfolio = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", dealerDetails.name);
    formData.append("designation", dealerDetails.designation);
    formData.append("companyName", dealerDetails.companyName);
    formData.append("address", dealerDetails.address);
    formData.append("gstImage", dealerDetails.gstImage);
    formData.append("panImage", dealerDetails.panImage);
    formData.append("upiDetails", dealerDetails.upiDetails);
    formData.append("bankDetailsImage", dealerDetails.bankDetailsImage);
    formData.append("aadharImage", dealerDetails.aadharImage);
    formData.append("serviceLocationState", dealerDetails.serviceLocationState);
    formData.append("serviceLocationCity", dealerDetails.serviceLocationCity);
    formData.append(
      "businessContactNumber",
      dealerDetails.businessContactNumber
    );
    DEALER_MANAGE.updatePortfolio(formData, params?.id).then((resp) => {
      if (resp.status === 200) {
        toast.success(resp.message, {
          position: "top-right",
        });
        navigate("/dealer");
      } else {
        toast.error(resp.message, {
          position: "top-right",
        });
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <main className="dashboard_container_main">
        <Sidebar />
        <div className="dashboard_container_right_panel" id="report_page">
          <Navbar />
          <div className="cards_container">
            <div className="profile_title">
              <h5 className="p-2 mb-0">
                {params?.id ? "Update Application" : "View Application"}
              </h5>
            </div>
          </div>
          {params.id ? (
            <div className="cards_container">
              <div className="profile_container">
                <form onSubmit={UpdateDealerPortfolio}>
                  <div className="row m-5">
                    <div className="col-md-6 ">
                      <label htmlFor="profile">Name</label>
                      <input
                        type="text"
                        placeholder={
                          user?.name ? user.name : "Name as per ID Proof"
                        }
                        id="profile"
                        value={dealerDetails.name}
                        name="name"
                        onChange={handleChange}
                        className="profile_input_edit"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile">Designation</label>
                      <input
                        type="text"
                        placeholder={
                          user?.designation ? user.designation : "Designation"
                        }
                        id="profile"
                        value={dealerDetails.designation}
                        name="designation"
                        onChange={handleChange}
                        className="profile_input_edit"
                      />
                    </div>
                  </div>
                  <div className="row m-5">
                    <div className="col-md-6">
                      <label htmlFor="profile">Company Name</label>
                      <input
                        type="text"
                        placeholder={
                          user?.companyName ? user.companyName : "Company Name"
                        }
                        id="profile"
                        value={dealerDetails.companyName}
                        name="companyName"
                        onChange={handleChange}
                        className="profile_input_edit"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile">Address</label>
                      <input
                        type="text"
                        placeholder={user?.address ? user.address : "Address"}
                        id="profile"
                        value={dealerDetails.address}
                        name="address"
                        onChange={handleChange}
                        className="profile_input_edit"
                      />
                    </div>
                  </div>
                  <div className="row m-5">
                    <div className="col-md-6">
                      <label htmlFor="profile">GST</label>
                      {user?.gstImage ? (
                        ""
                      ) : (
                        <input
                          type="file"
                          id="profile"
                          name="gstImage"
                          onChange={handleChange}
                          className="profile_input_edit"
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile">PAN</label>
                      {user?.panImage ? (
                        ""
                      ) : (
                        <input
                          type="file"
                          id="profile"
                          name="panImage"
                          onChange={handleChange}
                          className="profile_input_edit"
                        />
                      )}
                    </div>
                  </div>
                  <div className="row m-5">
                    <div className="col-md-6">
                      <label htmlFor="profile">UPI Details</label>
                      {user?.upiDetails ? (
                        ""
                      ) : (
                        <input
                          type="text"
                          id="profile"
                          value={dealerDetails.upiDetails}
                          name="upiDetails"
                          onChange={handleChange}
                          className="profile_input_edit"
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile">Bank Details</label>
                      {user?.bankDetailsImage ? (
                        ""
                      ) : (
                        <input
                          type="file"
                          id="profile"
                          name="bankDetailsImage"
                          onChange={handleChange}
                          className="profile_input_edit"
                        />
                      )}
                    </div>
                  </div>
                  <div className="row m-5">
                    <div className="col-md-6">
                      <label htmlFor="profile">Aadhar</label>
                      {user?.aadharImage ? (
                        ""
                      ) : (
                        <input
                          type="file"
                          id="profile"
                          name="aadharImage"
                          onChange={handleChange}
                          className="profile_input_edit"
                        />
                      )}
                    </div>
                    <div className="col-md-6 ">
                      <label htmlFor="profile">Business Contact Number</label>
                      <input
                        type="text"
                        placeholder={
                          user?.businessContactNumber
                            ? user.businessContactNumber
                            : "Business Contact Number"
                        }
                        id="profile"
                        name="businessContactNumber"
                        value={dealerDetails.businessContactNumber}
                        onChange={handleChange}
                        className="profile_input_edit"
                      />
                    </div>
                  </div>
                  <div className="row m-5">
                    <div className="col-md-6 ">
                      <label htmlFor="profile">Service Location State</label>
                      <select
                        className="profile_input_edit form-select"
                        name="serviceLocationState"
                        value={dealerDetails.serviceLocationState}
                        onChange={handleChange}
                      >
                        <option value="">
                          {dealerDetails?.serviceLocationState
                            ? dealerDetails.serviceLocationState
                            : "Select State"}
                        </option>
                        {stateList?.length > 0 &&
                          stateList?.map((state, index) => {
                            return (
                              <option key={index} value={state.isoCode}>
                                {state.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile">Service Location City</label>
                      <select
                        className="profile_input_edit form-select"
                        name="serviceLocationCity"
                        value={dealerDetails.serviceLocationCity}
                        onChange={handleChange}
                      >
                        <option value="">
                          {dealerDetails?.serviceLocationCity
                            ? dealerDetails.serviceLocationCity
                            : "Select City"}
                        </option>
                        {cityList?.length > 0 &&
                          cityList?.map((city, index) => {
                            return (
                              <option key={index} value={city.name}>
                                {city.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {params.id ? (
                    <button
                      type="submit"
                      className="btn btn-success float-end m-5"
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success float-end m-5"
                    >
                      <Link
                        className="text-light text-decoration-none"
                        to={`/dealer/edit-application/${user?._id}`}
                      >
                        Edit
                      </Link>
                    </button>
                  )}
                </form>
              </div>
            </div>
          ) : (
            <div className="cards_container">
              <div className="profile_container">
                <form>
                  <div className="row m-5">
                    <div className="col-md-6 ">
                      <label htmlFor="profile">Name</label>
                      <input
                        type="text"
                        placeholder={
                          user?.name ? user.name : "Name as per ID Proof"
                        }
                        id="profile"
                        value={dealerDetails.name}
                        name="name"
                        onChange={handleChange}
                        className="profile_input"
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile">Designation</label>
                      <input
                        type="text"
                        placeholder={
                          user?.designation ? user.designation : "Designation"
                        }
                        id="profile"
                        value={dealerDetails.designation}
                        name="designation"
                        onChange={handleChange}
                        className="profile_input"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row m-5">
                    <div className="col-md-6">
                      <label htmlFor="profile">Company Name</label>
                      <input
                        type="text"
                        placeholder={
                          user?.companyName ? user.companyName : "Company Name"
                        }
                        id="profile"
                        value={dealerDetails.companyName}
                        name="companyName"
                        onChange={handleChange}
                        className="profile_input"
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile">Address</label>
                      <input
                        type="text"
                        placeholder={user?.address ? user.address : "Address"}
                        id="profile"
                        value={dealerDetails.address}
                        name="address"
                        onChange={handleChange}
                        className="profile_input"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row m-5">
                    <div className="col-md-6">
                      <label htmlFor="profile">GST</label>
                      <br />
                      {dealerDetails.gstImage ? (
                        <img
                          src={`${baseUrl}/${dealerDetails.gstImage}`}
                          alt="document"
                          className="img-fluid mt-2"
                          style={{ width: "30%", height: "100px" }}
                        />
                      ) : (
                        <input
                          type="file"
                          id="profile"
                          name="gstImage"
                          onChange={handleChange}
                          className="profile_input"
                          disabled
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile">PAN</label>
                      <br />
                      {dealerDetails.panImage ? (
                        <img
                          src={`${baseUrl}/${dealerDetails.panImage}`}
                          alt="document"
                          className="img-fluid mt-2"
                          style={{ width: "30%", height: "100px" }}
                        />
                      ) : (
                        <input
                          type="file"
                          id="profile"
                          name="panImage"
                          onChange={handleChange}
                          className="profile_input"
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="row m-5">
                    <div className="col-md-6">
                      <label htmlFor="profile">UPI Details</label>
                      {user?.upiDetails ? (
                        ""
                      ) : (
                        <input
                          type="text"
                          id="profile"
                          value={dealerDetails.upiDetails}
                          name="paymentMethod"
                          onChange={handleChange}
                          className="profile_input"
                          disabled
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile">Bank Details</label>
                      <br />
                      {dealerDetails.bankDetailsImage ? (
                        <img
                          src={`${baseUrl}/${dealerDetails.bankDetailsImage}`}
                          alt="document"
                          className="img-fluid mt-2"
                          style={{ width: "30%", height: "100px" }}
                        />
                      ) : (
                        <input
                          type="file"
                          id="profile"
                          name="bankDetailsImage"
                          onChange={handleChange}
                          className="profile_input"
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div className="row m-5">
                    <div className="col-md-6">
                      <label htmlFor="profile">Aadhar</label>
                      <br />
                      {dealerDetails.aadharImage ? (
                        <img
                          src={`${baseUrl}/${dealerDetails.aadharImage}`}
                          alt="document"
                          className="img-fluid  mt-2"
                          style={{ width: "30%", height: "100px" }}
                        />
                      ) : (
                        <input
                          type="file"
                          id="profile"
                          name="aadharImage"
                          onChange={handleChange}
                          className="profile_input"
                          disabled
                        />
                      )}
                    </div>
                    <div className="col-md-6 ">
                      <label htmlFor="profile">Business Contact Number</label>
                      <input
                        type="text"
                        placeholder={
                          user?.businessContactNumber
                            ? user.businessContactNumber
                            : "Business Contact Number"
                        }
                        id="profile"
                        name="businessContactNumber"
                        value={dealerDetails.businessContactNumber}
                        onChange={handleChange}
                        className="profile_input"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="row m-5">
                    <div className="col-md-6 ">
                      <label htmlFor="profile">Service Location State</label>
                      <select
                        className="profile_input form-select"
                        name="serviceLocationState"
                        value={dealerDetails.serviceLocationState}
                        onChange={handleChange}
                        disabled
                      >
                        <option>
                          {dealerDetails?.serviceLocationState
                            ? dealerDetails.serviceLocationState
                            : "Select State"}
                        </option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile">Service Location City</label>
                      <select
                        className="profile_input form-select"
                        name="serviceLocationCity"
                        value={dealerDetails.serviceLocationCity}
                        onChange={handleChange}
                        disabled
                      >
                        <option>
                          {dealerDetails?.serviceLocationCity
                            ? dealerDetails.serviceLocationCity
                            : "Select City"}
                        </option>
                      </select>
                    </div>
                  </div>
                  {params.id ? (
                    <button
                      type="button"
                      className="btn btn-success float-end m-5"
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success float-end m-5"
                    >
                      <Link
                        className="text-light text-decoration-none"
                        to={`/dealer/edit-application/${user?._id}`}
                      >
                        Edit
                      </Link>
                    </button>
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default Application;
