import URL from "./apiUrl";

export const API = {
    Signup: async(data)=>{
        // console.log(data)
        try{
            const response = await fetch(URL + "user/signup", {
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    login: async(data)=>{
        // console.log(data)
        try{
            const response = await fetch(URL + "user/login", {
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    updateProfile: async (data, id) => {
        try {
          const response = await fetch(URL + `user/update-profile/${id}`, {
            method: "put",
            body: data,
          });
          const returnData = await response.json();
          return returnData;
        } catch (error) {
          return error;
        }
      },
    getAllUsers: async()=>{
        try{
            const response = await fetch(URL + "user/all-users", {
                method: "get",
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    deleteUser: async(id)=>{
        try{
            const response = await fetch(URL + `user/delete-user/${id}`, {
                method: "delete",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    getUserById: async(id)=>{
        try{
            const response = await fetch(URL + `user/details/${id}`, {
                method: "get",
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    reset: async(data)=>{
        try{
            const response = await fetch(URL + "user/forgot-Password", {
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    verifyToken: async(token)=>{
        try{
            const response = await fetch(URL + "user/verify-token", {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "authorization":token
                }
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    verifyEmail: async(data)=>{
        // console.log(email)
        try{
            const response = await fetch(URL + `user/email-verify`, {
                method: "post",
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    verifyOtp: async(data)=>{
        // console.log(email)
        try{
            const response = await fetch(URL + `user/otp-verify`, {
                method: "post",
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    estimateQuery: async(data)=>{
        try{
            const response = await fetch(URL + "user/estimate-query", {
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    getEstimateQuery: async()=>{
        try{
            const response = await fetch(URL + "user/all-estimate-query", {
                method: "get",
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
}