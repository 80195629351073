import React, { useContext, useEffect, useState } from "react";
import "../../Css/construction.css";
import { NavLink } from "react-router-dom";
// import { API } from "../../API/usersAPI";
import { ProfileContext } from "../../App";

const PopUp = () => {
  const { estimatePopup, setEstimatePopup,setEstimateData } = useContext(ProfileContext);
  const [details, setDetails] = useState({
    city: "",
    plotArea: "",
    slabArea: "",
    floors: "",
    building_type: "",
  });
  // useEffect(()=>{
  //   setEstimatePopup(!estimatePopup);
  // },[])

  const estimateChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setEstimateData(details);
    localStorage.setItem("estimate_data",JSON.stringify(details));
    setEstimatePopup(!estimatePopup);
   
  };
  return (
    <>
      <div className="popup" id="popup-1">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-4 mx-auto d-block">
              <div className="card">
                <div className="popup-card">
                <h5 className="card-title">
                      House Information
                      
                    </h5>
                  <div className="card-body p-3">
                    <div className="modal-body">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-10 mt-2 mb-2 mx-auto d-block">
                            <select
                              id="building_type"
                              className="form-select"
                              name="building_type"
                              value={details.building_type}
                              onChange={estimateChange}
                              required={true}
                            >
                              <option value="">Choose Building</option>
                              <option value="Residential">Residential</option>
                              <option value="Commercial">Commercial</option>
                            </select>
                          </div>
                          <div className="col-lg-10 mt-2 mb-2 mx-auto d-block">
                            <select
                              // id="city"
                              className="form-select"
                              name="city"
                              value={details.city}
                              onChange={estimateChange}
                            >
                              <option value="">Choose City</option>
                              <option value="Patna">Patna</option>
                              <option value="Noida">Noida</option>
                              <option value="Gurgaon">Gurgaon</option>
                              <option value="Ranchi">Ranchi</option>{" "}
                            </select>
                          </div>
                          {/* <div className="col-lg-10 mt-2 mb-2 mx-auto d-block">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter city"
                              name="city"
                              value={details.city}
                              onChange={estimateChange}
                            />
                          </div> */}
                          <div className="col-lg-10 mt-2 mb-2 mx-auto d-block">
                            <select
                              // id="plotArea"
                              className="form-select"
                              name="plotArea"
                              value={details.plotArea}
                              onChange={estimateChange}
                            >
                              <option value="">Choose Plot Area</option>
                              <option value="5000 - 7000 Sqft">
                                5000 - 7000 Sqft
                              </option>
                              <option value="3000 - 5000 Sqft">
                                3000 - 5000 Sqft
                              </option>
                              <option value="2000 - 3000 Sqft">
                                2000 - 3000 Sqft
                              </option>
                              <option value="1000 - 2000 Sqft">
                                1000 - 2000 Sqft
                              </option>
                              <option value="500 - 1000 Sqft">
                                500 - 1000 Sqft
                              </option>
                            </select>
                          </div>
                          {/* <div className="col-lg-10 mt-2 mb-2 mx-auto d-block">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter plot area"
                              name="plotArea"
                              value={details.plotArea}
                              onChange={estimateChange}
                            />
                          </div> */}
                          <div className="col-lg-10 mt-2 mb-2 mx-auto d-block">
                            <select
                              // id="slabArea"
                              className="form-select"
                              name="slabArea"
                              value={details.slabArea}
                              onChange={estimateChange}
                            >
                              <option value="">Choose Slab/Roof Area</option>
                              <option value="5000 - 7000 Sqft">
                                5000 - 7000 Sqft
                              </option>
                              <option value="3000 - 5000 Sqft">
                                3000 - 5000 Sqft
                              </option>
                              <option value="2000 - 3000 Sqft">
                                2000 - 3000 Sqft
                              </option>
                              <option value="1000 - 2000 Sqft">
                                1000 - 2000 Sqft
                              </option>
                              <option value="500 - 1000 Sqft">
                                500 - 1000 Sqft
                              </option>
                            </select>
                          </div>
                          {/* <div className="col-lg-10 mt-2 mb-2 mx-auto d-block">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Build up area"
                              name="slabArea"
                              value={details.slabArea}
                              onChange={estimateChange}
                            />
                          </div> */}
                          <div className="col-lg-10 mt-2 mb-2 mx-auto d-block">
                            <select
                              // id="floors"
                              className="form-select"
                              name="floors"
                              value={details.floors}
                              onChange={estimateChange}
                              required={true}
                            >
                              <option value="">Choose Number of Floors</option>
                              <option value="1">1 (Ground Floor)</option>

                              <option value="2">2 ( G + 1 )</option>

                              <option value="3">3 ( G + 2 )</option>

                              <option value="4">4 ( G + 3 )</option>

                              <option value="5">5 ( G + 4 )</option>

                              <option value="6">6 ( G + 5 )</option>

                              <option value="7">7 ( G + 6 )</option>
                            </select>
                          </div>
                          <div className="col-lg-10 mt-2 mb-2 mx-auto d-block text-center">
                            <button
                              type="submit"
                              className="form-control bg-danger text-light"
                            >
                              Submit
                            </button>
                            {/* <NavLink to="/" className="p-2">
                              <button
                                type="button"
                                className="btn btn-danger "
                                onClick={() => setEstimatePopup(!estimatePopup)}
                              >
                                Cancel
                              </button>
                            </NavLink> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopUp;
